import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { usePlan } from "../../Context/PlanContext";
import toast from "react-hot-toast";

function ProtectedRoute({ children, ...rest }) {
  const user = localStorage.getItem("user");
  const {currentPlan} = usePlan();
  const {pathname} = useLocation()
  const path = pathname.split("/").filter(Boolean)[0]  

  if(rest.checkAccess && !currentPlan[path]){
    toast?.error("Please upgrade your plan to access this feature.");
    return <Navigate to={"/unauthorized"} replace />
  }

  // Redirect to login page only if user is not authenticated
  if (!user) {
    return <Navigate to={"/"} replace />;
  }

  // If user is authenticated, render the children components
  return children;
}

export default ProtectedRoute;
