import { CheckCircleIcon, CreditCardIcon } from "@heroicons/react/24/solid";
import { Card, CardBody, IconButton } from "@material-tailwind/react";
import React from "react";
import { useDetachPaymentMethod } from "../../Web-Hooks/Subscription/use-subscription";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { XMarkIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";

export const PaymentMethodCard = ({
  customerId,
  method,
  defaultMethod,
  selectPaymentMethod,
}) => {
  const { mutateAsync: DetachPaymentMethod, isPending: isRemoving } =
    useDetachPaymentMethod();    

  const handleDetachPaymentMethod = async () => {

    if (selectPaymentMethod === "AddNewMethod") {
      return;
    }
    if (selectPaymentMethod === defaultMethod){
      toast?.error("You can't remove default payment method")
      return;
    }
    try {
      const res = await DetachPaymentMethod({
        customerId: customerId,
        paymentMethodId: selectPaymentMethod,
      });
      if (res) {
        toast?.success("Payment method removed successfully");
      }
    } catch (error) {
      toast?.error(error.message);
    }
  };

  const { card } = method;
  const isDefault = defaultMethod === method?.id;

  return (
    <div className="flex items-center  justify-center gap-2">
      <Card
        className={`p-0 w-72 bg-secondary bg-opacity-10  shadow-lg rounded-xl ${
          isDefault ? "border border-secondary" : ""
        }`}
      >
        <CardBody className="grid grid-cols-2 gap-2 items-center p-2">
          {/* Card Type & Default Badge */}
          <div className="flex items-center gap-2">
            <CreditCardIcon className="h-4 w-4 text-secondary" />
            <span className="text-base font-semibold text-gray-800 capitalize">
              {card?.display_brand}
            </span>
          </div>

          {isDefault ? (
            <div className="flex justify-end">
              <CheckCircleIcon className="h-4 w-4 text-secondary" />
            </div>
          ) : (
            <div></div>
          )}

          {/* Masked Card Number */}
          <div className="text-sm text-secondary font-semibold col-span-1">
            **** **** **** {card?.last4}
          </div>

          {/* Expiry Date */}
          <div className="text-right text-secondary font-semibold text-sm">
            Expires: {card?.exp_month}/{card?.exp_year}
          </div>

          {/* Set as Default Button
        {!isDefault && (
          <Button
            variant="gradient"
            color="blue"
            className="col-span-2 w-full text-white font-medium"
            onClick={() => setDefaultMethod(last4)}
          >
            Set as Default
          </Button>
        )} */}
        </CardBody>
      </Card>
      {selectPaymentMethod === method?.id && (
        <IconButton
          size="sm"
          variant="text"
          className=" text-base font-semibold flex justify-center items-center gap-3"
          onClick={handleDetachPaymentMethod}
          disabled={selectPaymentMethod === "AddNewMethod"}
        >
          {isRemoving ? (
            <LoadingSpinner height={20} width={20} color={"#153C58"} />
          ) : (
            <XMarkIcon className="h-6 w-6 text-debatePalette-timer" />
          )}
        </IconButton>
      )}
    </div>
  );
};
