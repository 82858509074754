import { PlusCircleIcon } from "@heroicons/react/24/solid";
import React, { Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import { SideBarMenu } from "../../Components/UI/Menus/SideBarMenu";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { DebateOptions } from "../../Utils/Constant";
import { useGetUserById } from "../../Web-Hooks/Profile/use-profile";
import ManageSubscriptionButton from "./ManageSubscriptionButton";
import NotificationButton from "./NotificationButton";
import ProfileMenu from "./ProfileMenu";
import WorkspaceMenu from "./WorkspaceMenu";
import { usePlan } from "../../Context/PlanContext";

const Sidebar = ({ isNewWorkSpace, openProfile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const { user: loggedInUser, handleSetUser } = useAuth();
  const {subscribedPlan} = usePlan();
  const { selectedWorkspace } = useWorkspace();
  const { subWorkspaceList, selectedSubWorkspace } = useSubWorkspace();
  const { data: profileDetail } = useGetUserById(
    loggedInUser?.userId,
    selectedSubWorkspace?.subWorkSpaceId,
    selectedWorkspace?.workSpaceId
  );

  useEffect(() => {
    if (profileDetail) {
      handleSetUser({
        userName: profileDetail?.userName,
        role: profileDetail?.subworkspaceRole,
        userImage: profileDetail?.userImage,
        userUsedStorage: profileDetail?.userStorage,
      });
    } // eslint-disable-next-line
  }, [profileDetail]);

  return (
    <section className="h-full w-full bg-sideLayoutPrimary py-2">
      {!isNewWorkSpace && (
        <Fragment>
          {" "}
          <div className="flex md:flex-col flex-row h-full items-stretch justify-between">
            <div className="align-top">
              <div className="flex md:flex-col items-center md:item-start gap-4 md:gap-0 ">
                <WorkspaceMenu
                  loggedInUser={loggedInUser}
                  navigate={navigate}
                />
                <div className="hidden md:flex md:flex-col mt-4">
                  <NotificationButton
                    loggedInUser={loggedInUser}
                    navigate={navigate}
                  />
                  {!subscribedPlan?.isTrialPlanAvailable && (
                    <ManageSubscriptionButton
                      loggedInUser={loggedInUser}
                      navigate={navigate}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="align-bottom justify-center items-center flex md:flex-col flex-row gap-2">
              {subWorkspaceList?.length !== 0 && (
                <SideBarMenu
                  isArrow={false}
                  label={
                    <PlusCircleIcon className="w-14 h-14 text-debatePalette-background text-center" />
                    // <RoundedButton
                    //   SVG={PlusIcon}
                    //   BtnWidth={"w-12"}
                    //   BtnHeight={"h-12"}
                    //   className={"md:bg-blue-gray-800 bg-white/15 "}
                    //   svgClass={"!text-debatePalette-background"}
                    // />
                  }
                  options={DebateOptions}
                />
              )}
              {/* <DynamicSpeedDial actions={DebateSpeedOptions} /> */}

              <ProfileMenu
                openProfile={openProfile}
                profileDetail={profileDetail}
              />
            </div>
          </div>
        </Fragment>
      )}
      {isNewWorkSpace && (
        <div className="w-12">
          {" "}
          {pathname !== "/createprofile" ? (
            <ImageOrInitials initials={selectedWorkspace?.workSpaceName} />
          ) : null}
        </div>
      )}
    </section>
  );
};

export default Sidebar;
