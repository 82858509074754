import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button } from '@material-tailwind/react';
import { useAttachPaymentMethod } from '../../Web-Hooks/Subscription/use-subscription';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const AddPaymentMethod = ({ customerId, subscriptionId, setSelectPaymentMethod }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const {mutateAsync: attachPaymentMethod, isPending: isCreating} = useAttachPaymentMethod();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const {error} = await elements?.submit();
    if (error) {
      setError(error.message);
      setLoading(false);
      return;
    }

    const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
      elements,
    });

    if (stripeError) {
      setError(stripeError.message);
      setLoading(false);
      return;
    }

    // Send paymentMethod.id to your backend to attach it to the customer
    const response = await attachPaymentMethod({
      customerId,
      subscriptionId,
      paymentMethodId: paymentMethod.id
    })

    if(response.error){
      setError(response.error?.stripeError?.message)
      setLoading(false);
      return;
    }

    if (response.data) {
      setSelectPaymentMethod(response.data.id);
      setLoading(false);
      // Handle success (e.g., show a success message, refresh the payment methods list)
    } 
    setLoading(false);


  };

  return (
    <form className='flex flex-col gap-3 w-full' onSubmit={handleSubmit}>
      <PaymentElement />
      {error && <p className='text-red-500 text-wrap w-full'>{error}</p>}
      <Button className='bg-secondary w-full' type="submit" disabled={!stripe || loading || isCreating}>
        {loading ? 'Adding...' : 'Add Payment Method'}
      </Button>
    </form>
  );
};

const PaymentMethodForm = ({ customerId, subscriptionId, setSelectPaymentMethod }) => {
  return (
    <Elements stripe={stripePromise} options={{
        mode: "setup",
        currency: "usd",
        paymentMethodCreation: "manual",
    }} >
      <AddPaymentMethod customerId={customerId} subscriptionId={subscriptionId} setSelectPaymentMethod={setSelectPaymentMethod} />
    </Elements>
  );
};

export default PaymentMethodForm;