import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { PricingCard } from "../Payment/PricingCard";
import { useAuth } from "../../Context/AuthContext";
import {
  useGetSubscribedPlan,
  useGetSubscriptionPlans,
} from "../../Web-Hooks/Subscription/use-subscription";

export default function PlansTab({ isYearly }) {
  const [search, setSearch] = useSearchParams();

  const selectedTab = search.get("plan") || "base-plan";

  const { user } = useAuth();

  const { data: subscriptionPlans, isLoading } = useGetSubscriptionPlans();

  const { data: subscribedPlan } = useGetSubscribedPlan(user?.userId);

  const basePlan = subscriptionPlans?.filter((plan) => !plan.isAiPlan);
  const AIPlan = subscriptionPlans?.filter((plan) => plan.isAiPlan);

  const renderSkeleton = (count) => {
    return Array.from({ length: count }).map((_, i) => (
      <div
        className="relative w-72 shrink-0 shadow-lg rounded-lg p-6 border border-gray-300 animate-flashy"
        key={i}
      >
        <div className="text-xl font-bold mb-4 flex justify-center text-transparent">
          <p className="bg-gray-300 w-24 rounded-lg">&nbsp;</p>
        </div>
        <div className="text-xl font-bold mb-4 h-3 flex justify-center text-transparent">
          <p className="bg-gray-300 w-24 rounded-lg">&nbsp;</p>
        </div>
        <div className="font-bold  mb-6 h-12 text-lg flex justify-center">
          <p className="bg-gray-300 w-32 text-center rounded-lg">&nbsp;</p>
        </div>
        <ul className="text-sm text-gray-300 space-y-2 h-64">
          {[...Array(8)].map((_, j) => (
            <li key={`${i}-${j}`} className="flex items-center">
              <span className="mr-2 text-transparent">✔</span>
              <span className="bg-gray-300 text-transparent rounded w-full">
                &nbsp;
              </span>
            </li>
          ))}
        </ul>
        <button className="mt-6 w-full bg-gray-300 text-transparent py-2 px-4 rounded">
          &nbsp;
        </button>
      </div>
    ));
  };

  const data = [
    {
      label: "Base Plan",
      value: "base-plan",
      content: (
        <div className="animate-fade-in-delay sm:container md:mx-auto">
          <div className="flex flex-wrap justify-center gap-6">
            {isLoading && renderSkeleton(4)}
            {!isLoading &&
              basePlan?.map((plan, index) => (
                <div
                  key={plan.planId}
                  className="hover:scale-105 transform transition duration-300"
                  style={{ animationDelay: `${index * 100}ms` }}
                >
                  <PricingCard
                    key={index}
                    isYearly={isYearly}
                    plan={plan}
                    isDark={plan.bestValue}
                    subscribedPlan={subscribedPlan}
                  />
                </div>
              ))}
          </div>
        </div>
      ),
    },
    {
      label: "+ With Debato AI",
      value: "debato-plan",
      content: (
        <div className="animate-fade-in-delay sm:container md:mx-auto">
        <div className="flex flex-wrap justify-center gap-6">
          {isLoading && renderSkeleton(4)}
          {!isLoading &&
            AIPlan?.map((plan, index) => (
              <div
                key={plan.planId}
                className="hover:scale-105 transform transition duration-300"
                style={{ animationDelay: `${index * 100}ms` }}
              >
                <PricingCard
                  key={index}
                  isYearly={isYearly}
                  plan={plan}
                  isDark={plan.bestValue}
                  subscribedPlan={subscribedPlan}
                  theme={"AI"}
                />
              </div>
            ))}
        </div>
      </div>
      ),
    },
  ];

  return (
    <div className="w-full flex items-start justify-center mx-auto p-4">
      <Tabs value={selectedTab} className="w-full flex flex-col items-center justify-start">
        <TabsHeader
          className="shrink-0 w-96  bg-gray-300 rounded-full p-0 h-14"
          indicatorProps={{
            className: `shadow-none rounded-full p-0 h-full w-full ${
              selectedTab === "debato-plan"
                ? "bg-gradient-to-l from-[#A122DD] to-[#7647FC]"
                : "bg-secondary "
            }`,
          }}
        >
          {data.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => {
                setTimeout(
                  () => setSearch({ plan: value }, { replace: true }),
                  100
                );
              }}
            >
              <span
                className={`transition-all ease-in-out duration-500 ${
                  selectedTab === value
                    ? "text-white font-medium focus:outline-none"
                    : `px-3 transition-all ease-in-out duration-500 ${
                        value === "debato-plan"
                          ? "bg-gradient-to-r from-[#A122DD] to-[#7647FC] bg-clip-text text-transparent"
                          : "text-secondary font-medium focus:outline-none"
                      }`
                } `}
              >
                {label}
              </span>
            </Tab>
          ))}
        </TabsHeader>

        <TabsBody className="mt-4 w-full">
          {data.map(({ value, content }) => (
            <TabPanel
              key={value}
              value={value}
              className="flex flex-wrap justify-center gap-6 "
            >
              {content}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
}
