import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Checkbox,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import NoData from "../../Components/Functionality/NoData";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { useAuth } from "../../Context/AuthContext";
import { usePlan } from "../../Context/PlanContext";
import { handleUpgradePlan } from "../../Services/SweetAlert";
import { ADD_WORKSPACES } from "../../Utils/Constant";
import { getUTCDate } from "../../Utils/Helper";
import {
  useAcceptJoinRequest,
  useGetAllPendingRequests,
} from "../../Web-Hooks/Workspace/use-workspace";

const JoinRequest = () => {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const { data: pendingRequests, isLoading: isPendingRequestsLoading } =
    useGetAllPendingRequests(user?.email);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const { mutateAsync: handleJoin, isPending: isJoinPending } =
    useAcceptJoinRequest();
  const { currentPlan } = usePlan();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const workspacesData = queryClient.getQueryData(["workspaces", user?.userId]);

  const alreadyJoinedWorkspace = workspacesData?.listWorkspaces?.filter(
    (work) => work?.createdBy?.userId !== user?.userId
  );

  useEffect(() => {
    if (pendingRequests?.length > 0) {
      setOpen(true);
    } // eslint-disable-next-line
  }, [pendingRequests]);

  const validWorkspaces = selectedRequests?.filter(
    (item) => item?.SubworkspaceIds?.length > 0
  );

  const handleAccept = async () => {
    const workspaces = selectedRequests?.filter(
      (item) => item?.SubworkspaceIds?.length > 0
    );
    try {
      const response = await handleJoin({
        Email: user?.email,
        Workspaces: workspaces,
        CreatedAt: getUTCDate(),
      });
      if (response?.error) {
        toast?.error(response?.error?.message);
        setOpen(false);
        return;
      }
      toast.success(response?.message);
    } catch (error) {
      toast?.error(error.message);
    }
    setOpen(false);
  };

  const handleForumSelect = (e, req, request) => {
    const { checked } = e.target;
    const joined =
      alreadyJoinedWorkspace?.length + selectedRequests?.length + 1;
    if (currentPlan?.totalWorkspaceJoin < joined && checked) {
      handleUpgradePlan(ADD_WORKSPACES, () =>
        navigate("/upgrade-plan?plan=base-plan")
      );
      return;
    }

    setSelectedRequests((prev) => {
      const existingRequest = prev.find(
        (item) => item.WorkspaceId === req.workspaceId
      );

      if (checked) {
        if (existingRequest) {
          return prev.map((item) =>
            item.WorkspaceId === req.workspaceId
              ? {
                  ...item,
                  SubworkspaceIds: [
                    ...new Set([
                      ...item.SubworkspaceIds,
                      request.subWorkspaceId,
                    ]),
                  ], // Ensure unique entries
                }
              : item
          );
        } else {
          return [
            ...prev,
            {
              WorkspaceId: req.workspaceId,
              SubworkspaceIds: [request.subWorkspaceId],
            },
          ];
        }
      } else {
        return prev
          .map((item) =>
            item.WorkspaceId === req.workspaceId
              ? {
                  ...item,
                  SubworkspaceIds: item.SubworkspaceIds.filter(
                    (id) => id !== request.subWorkspaceId
                  ),
                }
              : item
          )
          .filter((item) => item.SubworkspaceIds.length > 0); // Remove empty workspaces
      }
    });
  };

  const isChecked = (workId, subId) => {
    return selectedRequests.some(
      (workspace) =>
        workspace.WorkspaceId === workId &&
        workspace.SubworkspaceIds.includes(subId)
    );
  };

  return (
    <Dialog open={open} size="sm">
      <DialogHeader className="text-debatePalette-title flex flex-col gap-2 items-start justify-center">
        <div className="flex justify-between items-center w-full">
          <Typography variant="h3">Invites</Typography>
          <IconButton variant="text" onClick={() => setOpen(false)}>
            <XMarkIcon className="w-5 h-5 text-debatePalette-title" />
          </IconButton>
        </div>
        <Typography variant="paragraph" className="font-semibold">
          You have {pendingRequests?.length} invites.
        </Typography>
        <Typography variant="paragraph" className="font-medium">
          Select all of the forums you wish to join from below.
        </Typography>
      </DialogHeader>
      <DialogBody className="p-2 px-6 grid grid-cols-1 items-start gap-2 justify-start max-h-96 overflow-y-auto">
        {pendingRequests?.length === 0 && !isPendingRequestsLoading && (
          <NoData size={"h-52 w-full"} />
        )}
        {isPendingRequestsLoading && (
          <LoadingSpinner height={40} width={40} color={"#008bfa"} />
        )}
        {!isPendingRequestsLoading &&
          pendingRequests?.length > 0 &&
          pendingRequests?.map((req) => (
            <div className="flex flex-col w-full" key={req?.workspaceId}>
              <Typography className="font-semibold text-debatePalette-title">
                {req?.workspaceName}
              </Typography>
              {req?.subworkspaces?.map((request) => (
                <div
                  key={request?.subWorkspaceId}
                  className="grid grid-cols-[1fr_auto]  w-full  items-center gap-x-2"
                >
                  <Checkbox
                    className=" before:block before:bg-blue-gray-500 before:w-12 before:h-12 before:rounded-full before:absolute before:top-2/4 before:left-2/4 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 hover:before:opacity-10 before:transition-opacity checked:bg-secondary checked:before:bg-secondary rounded-md !before:content-none"
                    iconProps={{
                      className:
                        " bg-secondary p-0 border-secondary rounded-md",
                    }}
                    checked={isChecked(
                      req?.workspaceId,
                      request?.subWorkspaceId
                    )}
                    id={request?.subWorkspaceId}
                    onChange={(e) => handleForumSelect(e, req, request)}
                    label={
                      <div>
                        <Typography className="font-medium text-secondary">
                          {request?.subWorkspaceName}
                        </Typography>
                      </div>
                    }
                    containerProps={{
                      className: " !justify-start !gap-2 py-2",
                    }}
                  />
                  <Typography className="!text-sm p-2 text-secondary !font-normal shrink-0">
                    {request?.userCount}
                  </Typography>
                </div>
              ))}
            </div>
          ))}
      </DialogBody>
      <DialogFooter className="gap-2">
        <Button
          loading={isJoinPending}
          className="bg-secondary"
          onClick={handleAccept}
          disabled={validWorkspaces?.length === 0}
        >
          <span>Accept</span>
        </Button>
        <Button
          variant="text"
          color="red"
          onClick={() => setOpen(false)}
          className="mr-1"
        >
          <span>Cancel</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default JoinRequest;
