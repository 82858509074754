import { CreditCardIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";

const ManageSubscriptionButton = ({ loggedInUser }) => {
  // const { mutateAsync: createCustomer } = useCreateCustomer();
  // const { pathname } = useLocation();

  // const handleClick = async () => {
  //   const customerResponse = await createCustomer({
  //     userId: loggedInUser?.userId,
  //     Email: loggedInUser?.email,
  //     Name: loggedInUser?.userName,
  //   });

  //   const customer = customerResponse.customerData;

  //   const { url, error } = await stripeService.billingPortal.sessions.create({
  //     customer: customer.id,
  //     return_url: `${window.location.origin}${pathname}`,
  //   });
  //   if (error) {
  //     console.log(error);
  //     return;
  //   }
  //   window.location.replace(url);
  // };

  return (
    <Link
      to={"https://billing.stripe.com/p/login/test_eVa14k41L2Vj7hS8ww"}
      target="_blank"
      variant="text"
      className="border-none p-2"
      // onClick={handleClick}
    >
      <Tooltip
        content="Manage Subscription"
        className="bg-white text-black border border-black"
      >
        <CreditCardIcon className="h-8 w-8 text-debatePalette-background" />
      </Tooltip>
    </Link>
  );
};

export default ManageSubscriptionButton;
