import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { signUp_V1 } from "../../api/auth/authApi";
import betaLogo from "../../Assets/Images/Beta.png";
import Logo from "../../Assets/Svg/Logo.svg";
import SignupFooter from "../../Components/Functionality/SignupFooter";
import UserAvtarUpload from "../../Components/UI/AvatarStack/UserAvtarUpload";
import BasicInputField from "../../Layout/BasicInputField";
import { LoginInfo } from "../../Utils/Constant";
import { getInitials } from "../../Utils/Helper";
import { useWorkspace } from "../../Context/WorkspaceContext";

export default function SignUpMain() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    Email: "",
    Username: "",
    picture: "",
    profilePicture: "",
  });
  const [query] = useSearchParams();
  const Email = query?.get("Email");
  const Username = query?.get("Username");
  const [emailError, setEmailError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const { selectWorkspace } = useWorkspace();

  useEffect(() => {
    selectWorkspace(null);
    localStorage.clear();
    if (Email) {
      setUser({ Email: Email });
    } // eslint-disable-next-line
  }, []);

  const { mutateAsync: signUp_mutate_V1, isPending } = useMutation({
    mutationFn: async (formData) => {
      return await signUp_V1(formData);
    },
    onSuccess: (response) => {
      if (response?.error) {
        toast?.error(response?.error?.message);
        navigate(`/?Email=${user.Email}`);
        return;
      }
      if (response?.data) {
        const userData = {
          username: user.Username,
          email: user.Email,
          Initials: getInitials(user.Username),
        };
        toast?.success(response?.data?.message);
        navigate(
          `/otp-login?Email=${user.Email}&userId=${response?.data?.userId}&isTrialPlanAvailable=${response?.data?.isTrialPlanAvailable}`,
          { state: { userData: userData }, replace: true }
        );
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // SignUpp btn Function
  const signUpFn = async (e) => {
    e.preventDefault();

    if (user.Email && user.Username) {
      const formValues = {
        Email: user?.Email,
        UserName: user?.Username,
        UserImage: user?.picture,
      };

      const formData = new FormData();
      for (const [key, value] of Object.entries(formValues)) {
        formData.append(key, value);
      }
      await signUp_mutate_V1(formData);
    } else if (!user.Email) {
      setEmailError(true);
    } else {
      setUsernameError(true);
    }
  };

  // Form Change Event
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Email validation regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (name === "Email") {
      // Check if the email is valid
      if (!emailRegex.test(value)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }

    if (name === "Username") {
      if (value === "") {
        setUsernameError(true);
      } else {
        setUsernameError(false);
      }
    }

    // Update user state
    setUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateUserImage = (file, url) => {
    setUser((prevValues) => ({
      ...prevValues,
      profilePicture: url,
    }));
    setUser((prevValues) => ({
      ...prevValues,
      picture: file,
    }));
  };

  return (
    <section className="flex justify-center items-center md:h-screen bg-white">
      <Card className=" flex-col justify-center md:max-h-screen shadow-none gap-4 px-4 md:px-10 py-3  border border-debatePalette-buttonBorder">
        <img
          src={betaLogo}
          alt=""
          className="absolute top-0 left-0"
          style={{ width: 100, height: 100 }}
        />
        {/* Card Header with Logo */}
        <CardHeader
          variant="gradient"
          color="transparent"
          className="grid max-h-14 md:max-h-20 mt-2  place-items-center shadow-none !static"
        >
          <img src={Logo} alt="" className="max-h-14 md:max-h-20" />
        </CardHeader>
        <CardBody className="flex flex-col gap-4 p-0">
          {/* TagLines */}
          <div className="flex flex-col text-center justify-center items-center gap-2">
            <Typography className="text-5xl md:text-7xl text-debatePalette-title font-semibold">
              Sign Up
            </Typography>
            <Typography className="text-3xl md:text-5xl text-debatePalette-title font-semibold">
              {LoginInfo.headLine}
            </Typography>
            <Typography variant="small" className="text-debatePalette-bodyText">
              {LoginInfo.subLine}
            </Typography>
          </div>
          {/* Email Input */}
          <form className="max-w-[100%] my-6" onSubmit={signUpFn}>
            <div className="flex flex-col w-full justify-center items-center gap-4 p-0">
              <UserAvtarUpload
                setPicture={updateUserImage}
                picture={user?.profilePicture}
                userName={user?.Username || Username}
              />
              {!user?.profilePicture && (
                <label className="text-debatePalette-title font-semibold ">
                  Choose a profile picture
                </label>
              )}
              <div className="mb-1 flex flex-col w-full md:w-2/3 gap-6">
                <BasicInputField
                  required={false}
                  label="Username"
                  value={user?.Username}
                  name="Username"
                  fieldSize="lg"
                  placeholder="John Doe"
                  handleChange={handleChange}
                  // className={"!border-debatePalette-buttonBorder"}
                  emailError={usernameError}
                  type="text"
                  errorMessage="Please enter username e.g.(John doe)"
                  autoComplete="name"
                  minLength={3}
                  maxLength={16}
                />
                <BasicInputField
                  label="Email"
                  value={user?.Email}
                  name="Email"
                  fieldSize="lg"
                  placeholder="name@workemail.com"
                  handleChange={handleChange}
                  // className={"!border-debatePalette-buttonBorder"}
                  emailError={emailError}
                  type="email"
                  errorMessage="Please enter valid email address"
                  autoComplete="email"
                />
                {/* Continue Button */}
                <Button
                  id="signupBtn"
                  disabled={emailError || !user?.Email}
                  loading={isPending}
                  type="submit"
                  variant="gradient"
                  fullWidth
                  color="amber"
                  className="text-black text-md inline-flex items-center justify-center w-full"
                >
                  Continue
                </Button>
              </div>
            </div>
          </form>
        </CardBody>
        {/* Footer */}
        <CardFooter className="pt-0 mt-5">
          <SignupFooter info={LoginInfo} />
        </CardFooter>
      </Card>
    </section>
  );
}
