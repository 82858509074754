import React, { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Outlet } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import FeedHeader from "../Components/Functionality/FeedHeader";
import FilePopup from "../Components/Functionality/PDFViewer";
import AddUserDetailsPopup from "../Components/Popup/AddUserDetailsPopup";
import Version from "../Components/UI/Version";
import { useAuth } from "../Context/AuthContext";
import { useProfile } from "../Context/openProfileContext";
import { useSubWorkspace } from "../Context/SubWorkspaceContext";
import { useWorkspace } from "../Context/WorkspaceContext";
import NotificationDrawer from "../Pages/Notification/NotificationDrawer";
import ProfileDrawer from "../Pages/Profile/ProfileDrawer";
import useDebounce from "../Services/useDebounce";
import { extractPathname } from "../Utils/Helper";
import { useGetUserById } from "../Web-Hooks/Profile/use-profile";
import Header from "./Header/Header";
import MobileLayout from "./MobileLayout";
import FeedSidebar from "./Sidebar/FeedSidebar";
import Sidebar from "./Sidebar/Sidebar";
import { useSocket } from "../Context/SocketContext";
import AgentLayout from "../Pages/GlobalAgent/AgentLayout";

const HomeLayout = () => {
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace: subWorkspace, SubWorkspaceUser } =
    useSubWorkspace();
  const [search, setSearch] = useState("");
  const location = useLocation();
  const { pathname, search: searchQuery } = location;
  const query = new URLSearchParams(searchQuery);
  const userName = query?.get("userName");
  const { openRight, setOpenRight } = useProfile();
  const [openNotification, setOpenNotification] = useState(false);
  const openDrawerNotification = () => setOpenNotification(true);
  const closeDrawerNotification = () => setOpenNotification(false);
  const { initializeConnection } = useSocket();
  const openDrawerRight = () => {
    setOpenRight(true);
  };
  const closeDrawerRight = () => setOpenRight(false);
  const isTab = extractPathname(pathname) === "notification";
  const navigate = useNavigate();
  // const containerRef = useRef(null);
  const { user: loggedInUser } = useAuth();
  const {
    data: profileDetails,
    status,
    isFetching,
  } = useGetUserById(
    loggedInUser?.userId,
    subWorkspace?.subWorkSpaceId,
    selectedWorkspace?.workSpaceId
  );
  const [openAddUserDetails, setOpenAddUserDetails] = useState(false);

  const debounceSearch = useDebounce(search, 500);

  const handleSearch = useCallback(
    () => {
      if (debounceSearch.trim() === "") {
        pathname === "/search" &&
          navigate({ pathname: "/feed-public", search: "" });
      } else {
        pathname !== "/search"
          ? navigate(`/search?search=${encodeURIComponent(debounceSearch)}`)
          : navigate({
              pathname: pathname,
              search: `?search=${encodeURIComponent(debounceSearch)}`, // remove # from search query
            });
      }
    }, // eslint-disable-next-line
    [debounceSearch, navigate]
  );

  useEffect(() => {
    if (loggedInUser?.userId) {
      initializeConnection(loggedInUser?.userId, loggedInUser?.userName);
    }
  }, [loggedInUser, initializeConnection]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  useEffect(() => {
    // When the path changes, check if it's outside "create-debate" paths
    if (!location.pathname.startsWith("/createDebate")) {
      localStorage.removeItem("Debate");
      localStorage.removeItem("Citation");
      localStorage.removeItem("Viewers");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (status === "success") {
      setOpenAddUserDetails(profileDetails?.userName ? false : true);
    }
  }, [profileDetails, status]);

  const excludedRoutes = [
    "/createDebate",
    "/library",
    "/role",
    "/notification",
    "/reference",
    "/debate/",
    "/message/",
    "/group-message/",
  ];

  const shouldShowGlobalAIAgent = !excludedRoutes.some((route) =>
    pathname.includes(route)
  );

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div className="h-screen">
      <FilePopup />
      <Version />

      {shouldShowGlobalAIAgent && <AgentLayout />}
      {!isMobile ? (
        <div className=" h-full bg-sideLayoutPrimary">
          <header className="h-16 ">
            <Header
              setSearch={setSearch}
              search={search}
              openProfile={openDrawerRight}
              openDrawerNotification={openDrawerNotification}
            />{" "}
          </header>
          <section className="flex h-[calc(100vh-4rem)]">
            <section className="w-[72px] h-full bg-primary">
              <Sidebar openProfile={openDrawerRight} />
            </section>
            <section className="w-64 h-full bg-secondary rounded-l-md">
              <FeedSidebar user={selectedWorkspace} />
            </section>
            <section className="flex-1 bg-white relative">
              {extractPathname(pathname)[0] !== "createprofile" &&
                extractPathname(pathname)[0] !== "message" && extractPathname(pathname)[0] !== "group-message" && (
                  <div className="h-16">
                    <FeedHeader
                      apiType={pathname}
                      isTab={isTab}
                      AvtarStackCount={SubWorkspaceUser?.length}
                      userName={userName}
                    />
                  </div>
                )}
              <div
                className={`md:w-[calc(100vw-20.5rem)] h-[calc(100vh-8rem)] ${
                  (extractPathname(pathname)[0] !== "message" && extractPathname(pathname)[0] !== "group-message") &&
                  "overflow-y-auto"
                }`}
              >
                <Outlet />
              </div>
            </section>
          </section>
        </div>
      ) : (
        <div className="h-full">
          <MobileLayout
            search={search}
            setSearch={setSearch}
            isTab={isTab}
            openProfile={openDrawerRight}
          >
            <Outlet />
          </MobileLayout>
        </div>
      )}

      <ProfileDrawer
        openRight={openRight}
        closeDrawerRight={closeDrawerRight}
      />
      <NotificationDrawer
        openNotification={openNotification}
        closeOpenNotification={closeDrawerNotification}
      />
      <AddUserDetailsPopup
        isFetching={isFetching}
        open={openAddUserDetails}
        user={profileDetails}
        handleClose={() => setOpenAddUserDetails(false)}
      />
    </div>
  );
};

export default HomeLayout;
