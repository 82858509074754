import "animate.css";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const PaymentProcessing = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const amount = search.get("amount");
  const plan = search.get("plan");

  const handleClick = () => {
    navigate("/upgrade-plan?plan=base-plan", { replace: true });
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center bg-white p-8 rounded-lg shadow-lg">
        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-6 bg-blue-100 rounded-full animate__animated animate__zoomIn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 h-8 text-blue-600 animate-spin"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4v4m0 8v4m4-4h4m-8 0H8"
            />
          </svg>
        </div>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Payment Processing
        </h2>
        <p className="text-gray-600 mb-6">
          Please wait while we process your payment of ${amount} for the {plan} plan. 
          This may take a few moments.
        </p>
        <button
          className="px-6 py-3 text-white bg-blue-600 hover:bg-blue-700 rounded-lg shadow transition"
          onClick={handleClick}
        >
          Cancel Payment
        </button>
      </div>
    </div>
  );
};

export default PaymentProcessing;
