import {
  ADD_WORKSPACE,
  CREATE_WORKSPACE,
  GET_ALL_PENDING_REQUESTS,
  GET_ALL_WORKSPACE,
  GET_USERS_IN_WORKSPACE,
  GET_WORKSPACE,
  JOIN_WORKSPACE,
  REMOVE_USER_FROM_WORKSPACE,
  UPDATE_WORKSPACE,
} from "../apiRoutes";
import AXIOS_FORMDATA_INSTANCE from "../axiosFormDataInstance";
import AXIOS_INSTANCE from "../axiosInstance";
import { getDataINSTANCE } from "../controller";
export const getWorkspaces = async () => {
  try {
    const data = await getDataINSTANCE(GET_ALL_WORKSPACE);
    return data;
  } catch (error) {
    throw new Error("Failed to fetch workspaces");
  }
};

export const addWorkspace = async (workspace) => {
  try {
    const {
      data: { message: data },
    } = await AXIOS_FORMDATA_INSTANCE.post(ADD_WORKSPACE, workspace);
    return data;
  } catch (error) {
    throw new Error("Failed to add workspace");
  }
};
export const createWorkspace = async (workspace) => {
  try {
    const {
      data,
    } = await AXIOS_INSTANCE.post(CREATE_WORKSPACE, workspace);
    return data;
  } catch (error) {
    throw new Error("Failed to add workspace");
  }
};

export const updateWorkspace = async (updatedWorkspace) => {
  try {
    const data = await AXIOS_INSTANCE.post(
      `${UPDATE_WORKSPACE}`,
      updatedWorkspace
    );
    return data;
  } catch (error) {
    throw new Error("Failed to update workspace");
  }
};

export const getWorkspaceByUserId = async (userId) => {
  const data = await getDataINSTANCE(
    `${GET_WORKSPACE}?userId=${userId}`,
    "Failed to fetch workspace by id"
  );
  return data;
};

export const getWorkspaceUserByWorkspaceId = async (workId, userId, search) => {
  const data = await getDataINSTANCE(
    `${GET_USERS_IN_WORKSPACE}?workSpaceId=${workId}&loginUserId=${userId}&keyword=${search}`,
    "Failed to fetch workspace by id"
  );
  return data;
};

export const getAllPendingRequests = async (userEmail) => {
  const data = await AXIOS_INSTANCE(
    `${GET_ALL_PENDING_REQUESTS}?email=${userEmail}`
  );
  return data.data;
};

export const acceptJoinRequest = async (body) => {
  try {
    const {
      data: { message: data },
    } = await AXIOS_INSTANCE.post(`${JOIN_WORKSPACE}`, body);
    return data;
  } catch (error) {
    throw new Error("Failed to join forums");
  }
};

export const getWorkspaceById = async (workspaceId) => {
  const data = await getDataINSTANCE(
    `${GET_WORKSPACE}/workspaceId=${workspaceId}`,
    "Failed to fetch workspace by id"
  );
  return data;
};

export const deleteUserFromWorkspace = async (DeleteUser) => {
  try {
    const data = await AXIOS_INSTANCE.post(
      `${REMOVE_USER_FROM_WORKSPACE}`,
      DeleteUser
    );
    return data;
  } catch (error) {
    throw new Error("Failed to delete Workspace User");
  }
};
