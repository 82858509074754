import { Input } from "@material-tailwind/react";
import React from "react";

export default function InputField({
  value,
  FieldName,
  onChange,
  FieldSize,
  placeholder,
  labeClassName,
  FieldType,
  isError,
  isSuccess,
  errorMessage,
  className,
  inputName,
  containerClassName,
  refer,
  onKeyDown,
  onClick,
  icon,
  onBlur,
  disable,
  required,
  isControlled,
  maxLength = 1000
}) {
  return (
    <div>
      <label
        htmlFor={inputName}
        className={`flex gap-1 items-start ${labeClassName}`}
      >
        {FieldName}
        {required && (
          <span className="text-debatePalette-timer text-lg">*</span>
        )}
      </label>
      {!isControlled && (
        <Input
          id={inputName}
          inputRef={refer}
          containerProps={{
            className: containerClassName,
          }}
          maxLength={maxLength}
          disabled={disable}
          size={FieldSize}
          defaultValue={value}
          name={inputName}
          type={FieldType}
          onChange={onChange}
          onClick={onClick}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          onBlur={onBlur}
          className={
            isError || isSuccess
              ? isSuccess
                ? `!border-green-400 focus:!border-green-500  ${className}`
                : `!border-red-400 focus:!border-red-500  ${className}`
              : `!border-t-blue-gray-200 focus:!border-t-gray-900 placeholder-shown:text-debatePalette-title  ${className} autofill:!shadow-[inset_0_0_0px_1000px_rgb(255,255,255)]`
          }
          labelProps={{
            className: "before:content-none after:content-none",
            htmlFor: inputName,
          }}
          icon={icon}
          error={isError}
          success={isSuccess}
          autoComplete="off"
        />
      )}

      {isControlled && (
        <Input
          id={inputName}
          inputRef={refer}
          containerProps={{
            className: containerClassName,
          }}
          disabled={disable}
          size={FieldSize}
          value={value}
          name={inputName}
          type={FieldType}
          onChange={onChange}
          onClick={onClick}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          onBlur={onBlur}
          className={
            isError || isSuccess
              ? isSuccess
                ? `!border-green-400 focus:!border-green-500  ${className}`
                : `!border-red-400 focus:!border-red-500  ${className}`
              : `!border-t-blue-gray-200 focus:!border-t-gray-900  ${className} placeholder:!text-debatePalette-bodyText placeholder:!opacity-100  autofill:!shadow-[inset_0_0_0px_1000px_rgb(255,255,255)]`
          }
          labelProps={{
            className: "hidden",
          }}
          icon={icon}
          error={isError}
          success={isSuccess}
          maxLength={maxLength}
        />
      )}
      {isError && <p className="text-red-500 font-bold">{errorMessage}</p>}
    </div>
  );
}
