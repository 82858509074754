import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NoData from "../../Components/Functionality/NoData";
import CitationTagListPopup from "../../Components/Popup/CitationTagListPopup";
import DebateCard from "../../Components/UI/Cards/DebateCard";
import { RenderLoaderSkeleton } from "../../Components/UI/SkeletonLoader";
import { getNameFromEmail } from "../../Utils/Helper";
import ShareDebate from "./ShareDebate";

export default function Posts({
  apiType,
  AllDebates,
  user,
  isLoading,
}) {
  const [filteredPosts, setFilteredPosts] = useState([]);
  const navigate = useNavigate();
  const [citationPopup, setCitationPopup] = useState(false);
  const [debate, setDebate] = useState(null);
  const [shareDebatePopup, setShareDebatePopup] = useState(false);
  useLayoutEffect(() => {
    // const filteredDebates = AllDebates?.filter(debate => {
    //   if (apiType === "/voted") return true;
    //   if (apiType === "/feed") return debate.isDebatePublic;
    //   if (apiType === "/private") return debate.isDebatePrivate;
    //   if (apiType.slice(1).split("/")[0] === "debated") {
    //     return debate.createdBy === extractPathname(apiType)[1];
    //   }
    //   return false;
    // });
    setFilteredPosts(AllDebates);
  }, [apiType, AllDebates]);

  const renderNoDataMessage = () => {
    if (
      apiType === "/voted" ||
      apiType === "/voted-public" ||
      apiType === "/debated" ||
      apiType === "/debated-public"
    ) {
      return (
        <NoData
          size={"w-[100%] h-[80%]"}
          greet={`Welcome, ${
            user
              ? user?.userName
                ? user?.userName
                : getNameFromEmail(user?.email)
              : ""
          }`}
          message={
            "No data available. Click below to return to the feed and discover other debates."
          }
        >
          <Link
            className="cursor-pointer text-secondary text-xl font-bold underline hover:text-debatePalette-link"
            to={
              apiType === "/voted" || apiType === "/debated"
                ? "/feed"
                : "/feed-public"
            }
          >
            click here
          </Link>

          {/* <NoData.Buttons
            btn={"Click here to get started!"}
            handleClick={() =>
              navigate(apiType === "/voted" ? "/feed" : "/feed-public")
            }
          /> */}
        </NoData>
      );
    } else {
      return (
        <NoData
          size={"w-[100%] h-[80%]"}
          greet={`Welcome, ${
            user
              ? user?.userName
                ? user?.userName
                : getNameFromEmail(user?.email)
              : ""
          }`}
          message={
            "No debates yet. Click below to create your first debate and get started!"
          }
        >
          <NoData.Buttons
            btn={"Create Your First Debate"}
            handleClick={() => navigate("/createDebate")}
          />
        </NoData>
      );
    }
  };

  return (
    <div className={`flex w-full ${filteredPosts?.length === 0 && "h-full"} flex-col gap-8`}>
      <CitationTagListPopup
        debate={debate}
        citationPopup={citationPopup}
        setCitationPopup={setCitationPopup}
        setDebate={setDebate}
      />

      <ShareDebate
        shareDebatePopup={shareDebatePopup}
        setShareDebatePopup={setShareDebatePopup}
        debate={debate}
        setDebate={setDebate}
        enabled={shareDebatePopup}
      />
      {isLoading ? RenderLoaderSkeleton(3) : null}
      {filteredPosts?.length !== 0
        ? filteredPosts?.map((debate, i) => (
            <DebateCard
              debate={debate}
              key={debate?.debateId}
              setCitationPopup={setCitationPopup}
              setDebate={setDebate}
              setShareDebatePopup={setShareDebatePopup}
            />
          ))
        : renderNoDataMessage()}
    </div>
  );
}
