import React from "react";
import { Button, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

const UnauthorizedPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-100 to-gray-300 p-6">
      <div className="bg-white shadow-lg rounded-2xl p-8 flex flex-col gap-5 text-center w-full max-w-md">
        <div className="flex justify-center mb-4">
          <ExclamationTriangleIcon className="text-red-500 w-12 h-12" />
        </div>
        <Typography variant="h4" color="red" className="font-bold">
          Access Denied
        </Typography>
        <p className="text-debatePalette-title text-lg font-bold">
          This feature is not available on your current plan.
        </p>
        <p className="text-debatePalette-title font-semibold">Upgrade to unlock access!</p>

        <div className=" space-y-4">
          <Link to={"/upgrade-plan?plan=base-plan"}>
            <Button className="w-full bg-primary text-white font-semibold py-2 rounded-lg hover:bg-opacity-90">
              🔥 Explore Plans
            </Button>
          </Link>
          <Button
            className="w-full bg-gray-700 text-white font-semibold py-2 rounded-lg hover:bg-gray-800"
            onClick={() => (window.location.href = "/feed-public")}
          >
            🏠 Go to Feed
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
