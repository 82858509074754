import React from 'react';


export function PricingToggle({ isYearly, onChange }) {
  return (
    <div className="flex items-center justify-center gap-4">
      <span className={isYearly ? 'text-secondary text-opacity-70' : 'text-secondary font-bold '}>
      Monthly
      </span>
      <button
        onClick={() => onChange(!isYearly)}
        className={`w-14 h-8 ${isYearly ? 'bg-primary' : 'bg-secondary'}  rounded-full p-1 cursor-pointer`}
      >
        <div
          className={`w-5 h-5 bg-debatePalette-background rounded-full transition-transform duration-200 ${
            isYearly ? 'translate-x-7' : 'translate-x-0'
          }`}
        />
      </button>
      <span className={isYearly ? 'text-secondary font-bold' : 'text-secondary text-opacity-70'}>
        Annual
      </span>
    </div>
  );
}