import { Typography } from "@material-tailwind/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { JOIN_WORKSPACE, USER_CHECK } from "../../api/apiRoutes";
import AXIOS_INSTANCE from "../../api/axiosInstance";
import { getDataINSTANCE } from "../../api/controller";
import { getSubWorkspaceByWorkId } from "../../api/subworkspace/subworkspace";
import { getWorkspaceByUserId } from "../../api/workspace/workspace";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { useAuth } from "../../Context/AuthContext";
import { usePlan } from "../../Context/PlanContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { ADD_WORKSPACES } from "../../Utils/Constant";
import { getUTCDate } from "../../Utils/Helper";
import LogoType from "../../Assets/Svg/Logotype.svg";


const Join = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { selectWorkspace } = useWorkspace();
  const { selectSubWorkspace, selectWorkspaceId } = useSubWorkspace();
  const location = useLocation();
  const { search } = location;
  const [loader, setLoader] = useState(true);
  const filterDate = search?.split("&")[3]?.split("=")[1];
  const Email = search?.split("&")[2]?.split("=")[1];
  const WorkspaceId = search?.split("&")[0]?.split("=")[1];
  const SubWorkspaceId = search?.split("&")[1]?.split("=")[1];
  const loggedInUser = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : user;
  const { currentPlan } = usePlan();
  const queryClient = useQueryClient();

  const joinUser = async (body) => {
    try {
      const {
        data: { message: data },
      } = await AXIOS_INSTANCE.post(JOIN_WORKSPACE, body);
      return data;
    } catch (error) {
      throw new Error("Failed to join user");
    }
  };

  const { mutateAsync: handleJoin } = useMutation({
    mutationFn: async (body) => {
      return await joinUser(body);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["workspaces"],
        }),
      ]);
    },
  });

  useEffect(() => {
    if (filterDate) {
      const joinDate = new Date(filterDate);
      const joinDateFormat = new Date().toISOString();
      const currentDate = new Date(joinDateFormat);
      const dateDifference = currentDate - joinDate; // difference in hours
      const timeDifference = dateDifference / (1000 * 60 * 60);
      // difference in hours
      if (timeDifference > 48) {
        navigate("/invitation-expired", { replace: true });
      } else {
        handleProceed();
      }
    } // eslint-disable-next-line
  }, [filterDate]);

  const handleUpgradePlan = async({title, message}, cf) => {
    return Swal.fire({
      imageUrl: LogoType,
      imageWidth: 250,
      imageHeight: 150,
      imageAlt: "Custom image",
      width: 700,
      color: "#153C58",
      customClass: {
        title: "p-0 !my-2",
        image: "p-0 m-0 w-full mx-auto",
        popup: "flex flex-col items-center gap-3 ",
        htmlContainer: " !py-0 !px-16 !my-2",
        actions:"m-0 my-2",
        container:"!z-[99999]"
        
      },
      titleText: title,
      text: message,
      confirmButtonText: "Upgrade",
      showCancelButton: true,
      confirmButtonAriaLabel: "Upgrade",
      confirmButtonColor: "#153C58",
      cancelButtonAriaLabel: "cancel",
      cancelButtonText: "Cancel",
      stopKeydownPropagation: true,
      didClose: () => {
        navigate(-1)
      },
      showClass: {
        popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
      },
      hideClass: {
        popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
      },
    }).then((result) => {
      if (result.isConfirmed) {
        cf();
      }
    });
  }

  const handleProceed = async () => {
    setLoader(true);

    try {
      // Check if the user already exists in the system
      const data = await checkUser();

      if (!data?.userId || !data?.isUserExist) {
        // If the user does not exist, redirect the user to the signup page
        navigate(
          `/signup?Email=${Email}&WorkspaceId=${WorkspaceId}&SubWorkspaceId=${SubWorkspaceId}`,
          { state: { Email, WorkspaceId, SubWorkspaceId } }
        );
        return;
      }

      
      if (data?.userId !== loggedInUser?.userId) {
        // If the user is not the same as the logged in user, log the user out and redirect the user to the login page
        logout();
        navigate(
          `/?Email=${Email}&WorkspaceId=${WorkspaceId}&SubWorkspaceId=${SubWorkspaceId}`,
          { replace: true }
        );
        return;
      }

      // Check if the user has already joined the workspace
      if (currentPlan?.totalWorkspaceJoin !== "unlimited") {
        // If the user has not joined the workspace, check if the user has reached the maximum number of workspaces
        const workspace = await getWorkspaceByUserId(data?.userId);
        const joinedWorkspace = workspace?.listWorkspaces?.filter(
          (workspace) => workspace?.createdBy?.userId !== data?.userId
        );

        if (joinedWorkspace?.length >= currentPlan?.totalWorkspaceJoin) {
          // If the user has reached the maximum number of workspaces, show the upgrade plan popup
          handleUpgradePlan(ADD_WORKSPACES, () => {
            navigate("/upgrade-plan?plan=base-plan", { replace: true });
          });
          return;
        }
      }

      // If the user has not joined the workspace, call the join API
      const response = await handleJoin({
        Email: Email,
        Workspaces: [{
          WorkspaceId:WorkspaceId,
          SubworkspaceIds:[SubWorkspaceId]
        }],
        CreatedAt: getUTCDate(),
      });


      // Get the list of workspaces for the user
      const workspace = await getWorkspaceByUserId(response?.userId);
      const findOne = workspace?.listWorkspaces.find(
        (workspace) => workspace.workSpaceId === WorkspaceId
      );

      // Get the list of subworkspaces for the workspace
      const subworkspace = await getSubWorkspaceByWorkId(
        WorkspaceId,
        response?.userId
      );
      const subFindOne = subworkspace?.listOfSubworkspaces.find(
        (subworkspace) => subworkspace.subWorkSpaceId === SubWorkspaceId
      );

      if (findOne && subFindOne) {
        // If the workspace and subworkspace exist, select the workspace and subworkspace
        selectWorkspace(findOne);
        selectWorkspaceId(WorkspaceId);
        selectSubWorkspace(subFindOne);
        navigate("/feed", { replace: true });
      } else {
        // If the workspace or subworkspace does not exist, redirect the user to the create workspace page
        navigate("/createworkspace", {
          state: {
            userId: response?.userId,
            workspaceId: WorkspaceId,
            subworkspaceId: SubWorkspaceId,
          },
        });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoader(false);
    }
  };

  const checkUser = async () => {
    const data = await getDataINSTANCE(
      `${USER_CHECK}?Email=${Email}&WorkSpaceId=${WorkspaceId}&SubWorkspaceId=${SubWorkspaceId}`,
      "Failed to fetch Sub Workspaces Users"
    );
    return data;
  };

  return (
    <section className="h-screen flex flex-col md:flex-row items-center justify-between p-8">
      <div className="flex flex-col justify-center items-center gap-4  w-full">
        <Typography variant="h1">Hello, Welcome to Debatebase</Typography>
        {loader ? <LoadingSpinner /> : null}
      </div>
    </section>
  );
};

export default Join;
