import { LOGIN, REFRESH_TOKEN, SIGNUP, SIGNUP_V1 } from "../apiRoutes";
import AXIOS_FORMDATA_INSTANCE from "../axiosFormDataInstance";
import AXIOS_INSTANCE from "../axiosInstance";
import { postDataINSTANCE } from "../controller";

export const handleResponse = async (url, data) => {
  const { status, data: response } = await AXIOS_INSTANCE.post(url, data);
  const errorMessage =
    {
      400: "Bad Request",
      409: "User already exists",
      401: "Unauthorized",
    }[status] || "Something went wrong";
  if (status >= 400) throw new Error(errorMessage);
  return response;
};

export const handleGetResponse = async (url, data) => {
  const {
    status,
    data: { message: response },
  } = await AXIOS_INSTANCE.get(url, data);
  const errorMessage =
    {
      400: "Bad Request",
      409: "User already exists",
      401: "Unauthorized",
    }[status] || "Something went wrong";
  if (status >= 400) throw new Error(errorMessage);
  return response;
};

export const signUp_V1 = async (formdata) => {
  try {
    const { data } = await AXIOS_FORMDATA_INSTANCE.post(SIGNUP_V1, formdata);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return error.response.data;
    }
  }
};
export const signIn_V1 = async (userCredentials) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(SIGNUP, userCredentials);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return error.response.data;
    }
  }
};
export const signUpAPI = (email) => postDataINSTANCE(SIGNUP, email);
export const loginAPI = (credentials) => postDataINSTANCE(LOGIN, credentials);
export const refreshToken = (oldToken) =>
  handleGetResponse(`${REFRESH_TOKEN}?token=${oldToken}`);
