import {
  ATTACH_PAYMENT_METHOD,
  CANCEL_SUBSCRIPTION,
  CREATE_CUSTOMER,
  CREATE_PAYMENT_INTENT,
  CREATE_SETUP_INTENT,
  CREATE_SUBSCRIPTION,
  DETACH_PAYMENT_METHOD,
  GET_CUSTOMER_DETAILS,
  GET_CUSTOMER_PAYMENT_METHODS,
  GET_PRODUCT,
  GET_SUBSCRIPTION_PLAN,
  GET_UPCOMING_INVOICE,
  GET_USER_SUBSCRIPTION_PLAN,
  SET_PAYMENT_METHOD,
} from "../apiRoutes";
import AXIOS_INSTANCE from "../axiosInstance";

export const getSubscriptionPlans = async () => {
  const data = await AXIOS_INSTANCE.get(`${GET_SUBSCRIPTION_PLAN}`);
  return data;
};
export const getUserSubscribedPlan = async (userId) => {
  const data = await AXIOS_INSTANCE.get(
    `${GET_USER_SUBSCRIPTION_PLAN}?userId=${userId}`
  );
  return data;
};

export const getProductDetails = async (planId, plan) => {
  const data = await AXIOS_INSTANCE.get(
    `${GET_PRODUCT}?active=true&planId=${planId}&planType=${plan === "month" ? "monthly" : plan === "year" ? "yearly" : ""}`
  );
  return data;
};
export const getPaymentMethods = async (customerId) => {
  const data = await AXIOS_INSTANCE.get(
    `${GET_CUSTOMER_PAYMENT_METHODS}?customerId=${customerId}`
  );
  return data;
};
export const getCustomerDetails = async (customerId) => {
  const data = await AXIOS_INSTANCE.get(
    `${GET_CUSTOMER_DETAILS}?customerId=${customerId}`
  );
  return data;
};
export const getUpcomingInvoice = async (newPlan) => {
  const {customerId, newPriceId, subscriptionItemId, subscriptionId} = newPlan;
  const data = await AXIOS_INSTANCE.get(
    `${GET_UPCOMING_INVOICE}?customerId=${customerId}&newPriceId=${newPriceId}&subscriptionItemId=${subscriptionItemId}&subscriptionId=${subscriptionId}`, 
  );
  return data;
};
export const createCustomer = async (user) => {
  const data = await AXIOS_INSTANCE.post(
    `${CREATE_CUSTOMER}`, user
  );
  return data.data;
};
export const createPaymentIntent = async (user) => {
  const data = await AXIOS_INSTANCE.post(
    `${CREATE_PAYMENT_INTENT}`, user
  );
  return data.data;
};
export const createSubscription = async (user) => {
  const data = await AXIOS_INSTANCE.post(
    `${CREATE_SUBSCRIPTION}`, user
  );
  return data.data;
};
export const createSetupIntent = async (user) => {
  const data = await AXIOS_INSTANCE.post(
    `${CREATE_SETUP_INTENT}`, user
  );
  return data.data;
};
export const setDefaultPaymentMethod = async (paymentMethod) => {
  const data = await AXIOS_INSTANCE.post(
    `${SET_PAYMENT_METHOD}`, paymentMethod
  );
  return data.data;
};
export const attachPaymentMethod = async (paymentMethod) => {
  const data = await AXIOS_INSTANCE.post(
    `${ATTACH_PAYMENT_METHOD}`, paymentMethod
  );
  return data.data;
};
export const detachPaymentMethod = async (paymentMethod) => {
  const data = await AXIOS_INSTANCE.post(
    `${DETACH_PAYMENT_METHOD}`, paymentMethod
  );
  return data.data;
};
export const cancelSubscription = async ({subscriptionId}) => {
  const data = await AXIOS_INSTANCE.post(
    `${CANCEL_SUBSCRIPTION}?subscriptionId=${subscriptionId}`, 
  );
  return data.data;
};
