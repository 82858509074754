import moment from "moment";
import toast from "react-hot-toast";

export function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string.slice(1);
}

export function extractPathname(str) {
  const route = str.slice(1).split("/");
  return route;
}

export function extractTitle(url) {
  const urlParts = url.split("/")?.pop();
  const fileName = urlParts?.substring(urlParts?.indexOf("_") + 1) || "";
  return fileName;
}

export function formatTimestampToReadableDate(timestamp) {
  const now = moment();
  const date = moment(timestamp);

  if (date.isSame(now, "day")) {
    return `Today at ${date.format("hh:mm A")}`;
  } else if (date.isSame(now.subtract(1, "day"), "day")) {
    return `Yesterday at ${date.format("hh:mm A")}`;
  } else {
    return date.format("MMMM DD, YYYY [at] hh:mm A");
  }
}

export const ValidateRequiredFields = (
  fieldsToValidate,
  formData,
  setError
) => {
  const missingFields = fieldsToValidate.filter(
    (field) =>
      !formData[field.name] ||
      (field.name === "Fields" && formData.Fields.length === 0)
  );

  if (missingFields.length > 0) {
    const errorMessage =
      "Please fill " +
      missingFields
        .map((field) => {
          setError((prevError) => ({
            ...prevError,
            [field.name]: field.errorMessage,
          }));
          return field.displayName;
        })
        .join(", ");
    toast.error(errorMessage);
    return false;
  } else {
    return true;
  }
};

export const prepareFormData = (
  debateInfo,
  selectedSubWorkspace,
  user,
  draft,
  futureAction = ""
) => {
  const {
    Type,
    CustomeMaxVotesToWin,
    CustomTimeFrame,
    CustomRounds,
    NominateUserId,
    IsPublishWithoutOpponent,
    Fields,
  } = debateInfo;

  return {
    ...debateInfo,
    DebateImage: debateInfo?.DebateImage?.split("/")?.pop(),
    CustomeMaxVotesToWin: Type === "custom" ? CustomeMaxVotesToWin : null,
    CustomTimeFrame: Type === "custom" ? CustomTimeFrame : null,
    CustomRounds: Type === "custom" ? CustomRounds : null,
    SubWorkSpaceId: selectedSubWorkspace?.subWorkSpaceId,
    CreatedUserId: user?.userId,
    Fields: Array.isArray(Fields) ? Fields?.join(";") : Fields,
    IsPublishWithoutOpponent: draft
      ? false
      : NominateUserId === null
      ? true
      : IsPublishWithoutOpponent,
    IsOtheruserAllow: draft
      ? false
      : NominateUserId === null
      ? true
      : IsPublishWithoutOpponent,
    CreatedAt: getUTCDate(),
    AfterCronIsOpen: futureAction === "open",
    AfterCronIsSaveAsDraft: futureAction === "save",
    AfterCronIsNominateOtherUser: futureAction === "invite",
  };
};

export async function handleDownloadFile(url, filename) {
  const response = await fetch(url);
  const blob = await response.blob();
  const blobUrl = URL.createObjectURL(blob);
  const anchor = document.createElement("a");
  anchor.href = blobUrl;
  anchor.download = filename;
  document.body.appendChild(anchor);
  anchor.click();

  document.body.removeChild(anchor);
  URL.revokeObjectURL(blobUrl); // Clean up memory
}

export const updateDynamicFollowerFollowing = (
  oldData,
  userId,
  listKey = "debateList",
  type
) => {
  const latestData = oldData?.pages?.flatMap((page) => page[listKey]);
  const findData = latestData?.find((item) => item.userId === userId);

  if (findData) {
    if (type === "following") {
      const filteredData = latestData?.filter((item) => item.userId !== userId);
      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          [listKey]: filteredData,
        })),
      };
    }

    if (type === "searchUser") {
      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          [listKey]: page[listKey].map((item) =>
            item.userId === findData.userId
              ? {
                  ...findData,
                  followersCount: !findData?.isFollowing
                    ? findData.followersCount + 1
                    : findData.followersCount - 1,
                  isFollowing: !findData.isFollowing,
                }
              : item
          ),
        })),
      };
    }

    if (type === "follower") {
      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          [listKey]: page[listKey].map((item) =>
            item.userId === findData.userId
              ? {
                  ...findData,
                  isFollowBack: !findData.isFollowBack,
                }
              : item
          ),
        })),
      };
    }
  }
  return oldData;
};

export const updateDynamicSaveUnSaveReference = (
  oldData,
  referenceId,
  isSave,
  listKey = "referencesList",
  type
) => {
  const pathname = window.location.pathname;
  const latestData = oldData?.pages?.flatMap((page) => page[listKey]);
  if (type === "saved" && pathname === "/profile/mysave") {
    const filteredData = latestData?.filter(
      (item) => item.referenceId !== referenceId
    );
    return {
      ...oldData,
      pages: oldData.pages.map((page) => ({
        ...page,
        [listKey]: filteredData,
      })),
    };
  }
  if (type === "reference" && pathname === "/reference") {
    const findData = latestData?.find(
      (item) => item.referenceId === referenceId
    );
    if (findData) {
      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          [listKey]: page[listKey].map((item) =>
            item.referenceId === findData.referenceId
              ? {
                  ...findData,
                  isReferenceSaved: !!isSave,
                }
              : item
          ),
        })),
      };
    }
  }

  return oldData;
};
export const updateDynamicUpdateReference = (
  oldData,
  referenceId,
  NewReference,
  listKey = "referencesList",
  type
) => {
  const pathname = window.location.pathname;
  const latestData = oldData?.pages?.flatMap((page) => page[listKey]);
  if (type === "reference" && pathname === "/reference") {
    if (!referenceId) {
      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          [listKey]: [
            {
              referenceId: NewReference?.newReferenceId,
              title: NewReference?.Title,
              description: NewReference?.Description,
              referenceUrl: NewReference?.ReferenceUrl,
              referenceType: NewReference?.ReferenceType,
              createdAt: NewReference?.CreatedAt,
              isReferenceSaved: false,
              subworkspaceId: NewReference?.SubworkspaceId || "",
              subworkspaceName: null,
              workspaceId: NewReference?.WorkspaceId,
              createdByUserId: NewReference?.CreatedByUserId,
            },
            ...page[listKey],
          ],
        })),
      };
    }
    const findData = latestData?.find(
      (item) => item.referenceId === referenceId
    );
    if (findData) {
      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          [listKey]: page[listKey].map((item) =>
            item.referenceId === findData.referenceId
              ? {
                  ...findData,
                  title: NewReference?.Title,
                  description: NewReference?.Description,
                  referenceUrl: NewReference?.ReferenceUrl,
                  referenceType: NewReference?.ReferenceType,
                }
              : item
          ),
        })),
      };
    }
  }

  return oldData;
};
export const updateDynamicDeleteReference = (
  oldData,
  referenceId,
  listKey = "referencesList",
  type
) => {
  const latestData = oldData?.pages?.flatMap((page) => page[listKey]);

  if (type === "reference") {
    const findData = latestData?.find(
      (item) => item.referenceId === referenceId
    );
    if (findData) {
      const filteredData = latestData?.filter(
        (item) => item.referenceId !== referenceId
      );
      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          [listKey]: filteredData,
        })),
      };
    }
  }

  return oldData;
};

export const updateDynamicCitationCountDebateList = (
  oldData,
  debateId,
  listKey = "debateList"
) => {
  const latestData = oldData?.pages?.flatMap((page) => page[listKey]);
  const findData = latestData?.find((item) => item.debateId === debateId);

  if (findData) {
    return {
      ...oldData,
      pages: oldData.pages.map((page) => ({
        ...page,
        [listKey]: page[listKey].map((item) =>
          item.debateId === findData.debateId
            ? {
                ...findData,
                totalCitationCount: findData.totalCitationCount + 1,
              }
            : item
        ),
      })),
    };
  }
  return oldData;
};

/**
 * Converts a size string (e.g., "1GB", "1MB") to its value in kilobytes.
 * @param {string} sizeString - The size string (e.g., "1GB", "1MB").
 * @returns {number} - The size in kilobytes.
 * @throws {Error} - If the input string is invalid.
 */
export function convertToBytes(sizeString) {
  const units = {
    B: 1, // 1 Byte
    KB: 1024, // 1 KB = 1024 Bytes
    MB: 1024 * 1024, // 1 MB = 1024 * 1024 Bytes
    GB: 1024 * 1024 * 1024, // 1 GB = 1024 * 1024 * 1024 Bytes
    TB: 1024 * 1024 * 1024 * 1024, // 1 TB = 1024 * 1024 * 1024 * 1024 Bytes
  };

  const regex = /^(\d+(?:\.\d+)?)(B|KB|MB|GB|TB)$/i; // Matches "1GB", "1.5MB", etc.
  const match = sizeString.match(regex);

  if (!match) {
    throw new Error("Invalid size string. Example formats: '1GB', '1MB'.");
  }

  const value = parseFloat(match[1]);
  const unit = match[2].toUpperCase();

  if (!units[unit]) {
    throw new Error(`Unknown unit: ${unit}`);
  }

  return value * units[unit];
}
export const updateDynamicSaveCountDebateList = (
  oldData,
  debateId,
  listKey = "debateList"
) => {
  const latestData = oldData?.pages?.flatMap((page) => page[listKey]);
  const findData = latestData?.find((item) => item.debateId === debateId);

  if (findData) {
    return {
      ...oldData,
      pages: oldData.pages.map((page) => ({
        ...page,
        [listKey]: page[listKey].map((item) =>
          item.debateId === findData.debateId
            ? {
                ...findData,
                isDebateSaved: !findData?.isDebateSaved,
              }
            : item
        ),
      })),
    };
  }
  return oldData;
};
export const updateDynamicNotificationView = (
  oldData,
  notificationId,
  listKey = "notificationLists"
) => {
  const latestData = oldData?.pages?.flatMap((page) => page[listKey]);
  const findData = latestData?.find(
    (item) => item.notificationId === notificationId
  );
  if (findData) {
    return {
      ...oldData,
      pages: oldData.pages.map((page) => ({
        ...page,
        notificationCount: page.notificationCount - 1,
        [listKey]: page[listKey].map((item) =>
          item.notificationId === findData.notificationId
            ? {
                ...findData,
                isUserView: true,
              }
            : item
        ),
      })),
    };
  }
  return oldData;
};

export const getFileType = (name) => {
  const fileName = name.toLowerCase();
  if (
    fileName.endsWith(".jpeg") ||
    fileName.endsWith(".jpg") ||
    fileName.endsWith(".png")
  ) {
    return "image";
  } else if (fileName.endsWith(".doc") || fileName.endsWith(".docx")) {
    return "doc";
  } else if (fileName.endsWith(".pdf")) {
    return "pdf";
  } else if (
    fileName.endsWith(".mp4") ||
    fileName.endsWith(".mov") ||
    fileName.endsWith(".avi")
  ) {
    return "video";
  } else {
    // Assume it's a URL or handle other file types as needed
    // toast.error("File type not supported");
    return;
  }
};

export const getFileNameFromURL = (url) => {
  const urlParts = url.split("/");
  const fileName = urlParts[urlParts.length - 1];
  return fileName;
};

export function convertDateToUTC(date) {
  return moment(date).utc().format();
}
// Intials from Name
export function getInitials(name) {
  if (!name) return "";

  if (name.includes("@")) {
    // Handle email: extract initials from the username part (first 2 chars)
    const username = name.split("@")[0];
    return username
      .slice(0, 2)
      .toUpperCase()
      .replace(/[^a-zA-Z0-9]/g, "");
  }

  if (name.includes("-")) {
    // Handle hyphenated names
    const words = name.split("-");
    const initials = words
      .map((word) =>
        word
          .trim(" ")
          .charAt(0)
          .toUpperCase()
          .replace(/[^a-zA-Z0-9]/g, "")
      )
      .join("");
    return initials.slice(0, 2); // Take only the first 2 initials
  }

  if (name.includes(" ")) {
    // If the input contains spaces, assume it's a full name
    const words = name.split(" ");
    const initials = words
      .map((word) =>
        word
          .charAt(0)
          .toUpperCase()
          .replace(/[^a-zA-Z0-9]/g, "")
      )
      .join("");
    return initials.slice(0, 2); // Take only the first 2 initials
  }

  // For single-word names, take the first and middle initials
  const firstInitial = name
    .charAt(0)
    .toUpperCase()
    .replace(/[^a-zA-Z0-9]/g, ""); // Take the first character and convert to uppercase
  const middleInitial = name
    .charAt(Math.floor(name.length / 2))
    .toUpperCase()
    .replace(/[^a-zA-Z0-9]/g, ""); // Take the middle character and convert to uppercase
  return `${firstInitial}${middleInitial}`;
}
// Initials from email
export function getInitialsFromEmail(email) {
  const atIndex = email?.indexOf("@");
  if (atIndex !== -1) {
    let name = email?.substring(0, atIndex);
    return getInitials(name);
  } else {
    return getInitials(email);
  }
}

export function getNameFromEmail(email) {
  const atIndex = email?.indexOf("@");
  if (atIndex !== -1) {
    return email?.substring(0, atIndex);
  } else {
    return email;
  }
}

export function convertSubcurrencyToMain(currencyCode, subcurrencyAmount) {
  try {
    // Step 1: Dynamically determine the decimal places for the currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode.toUpperCase(),
    });

    // Extract the number of decimal places (e.g., 2 for USD, 0 for JPY)
    const decimalPlaces = formatter.resolvedOptions().minimumFractionDigits;

    // Step 2: Convert subcurrency to main currency
    const divisor = Math.pow(10, decimalPlaces);
    const mainAmount = subcurrencyAmount / divisor;

    // Step 3: Format the result
    return formatter.format(mainAmount);
  } catch (error) {
    return `Error: Currency "${currencyCode}" is invalid or unsupported.`;
  }
}

export function convertEpochToLocalTime(epochTime){
  const localDate = new Date(epochTime * 1000);
  return localDate;
}

export function convertSubCurrency(currency, factor = 100) {
  return Math.round(currency * factor);
}

// Based on return from date today or yesterday
export function isTodayOrYesterday(date) {
  const parsedDate = moment(date, "YYYY-MM-DD");

  // Check if the date is valid
  if (!parsedDate.isValid()) {
    // Return an error message
    return "Invalid date format";
  }

  // Get the current date using moment.js
  const today = moment();

  // Compare the date with the current date
  if (parsedDate.isSame(today, "day")) {
    // Return "today"
    return "Today";
  } else if (parsedDate.isSame(today.subtract(1, "day"), "day")) {
    // Return "yesterday"
    return "Yesterday";
  } else {
    // Format the date to MM-DD-YYYY using moment.js
    const formattedDate = parsedDate.format("MM-DD-YYYY");
    return formattedDate;
  }
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const buildFormData = (data, excludedFields) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (!excludedFields.includes(key)) {
      formData.append(key, value);
    }
  });
  return formData;
};

export const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!");
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};

export const copyObjectToClipboard = (data) => {
  const dataString = JSON.stringify(data); // Convert object to string
  navigator.clipboard
    .writeText(dataString)
    .then(() => {
      console.log("Object copied to clipboard successfully!");
    })
    .catch((err) => {
      console.error("Failed to copy object: ", err);
    });
};

export const getLocalTime = (time) => {
  const now = moment(time).local().fromNow();
  return now;
};

export const getUserImageUrl = (id, file) => {
  const image = file
    ? `${process.env.REACT_APP_AZURE_STORAGE_BASE_URL}/debatemodule/Users/${id}/${file}`
    : null;

  return image;
};

export const getWorkspaceRole = (workspace, userId) => {
  const userRole = workspace?.createdBy?.userId === userId ? "Admin" : "";
  return userRole;
};

export function isValidUrl(string) {
  const regex = /^(https?|ftp|file|blob|data):\/\/([^\s$.?#].[^\s]*)$/i;
  return regex.test(string);
}

export function getWordCount(text) {
  // Replace all newlines with spaces to treat newlines as word boundaries
  return text.trim().split(/\s+/).filter(Boolean).length;
}

export function getRoomName(senderId, receiverId) {
  const [lower, higher] = [senderId, receiverId].sort();
  return `room_${lower}_${higher}`;
}

// Helper function to trim text to the word limit
export const trimToWordLimit = (text, wordLimit) => {
  // Replace all newlines with spaces
  const wordsArray = text.replace(/\n/g, " ").trim().split(/\s+/);

  // Trim the text to the word limit
  return wordsArray.slice(0, wordLimit).join(" ");
};

export const getUTCDate = () => {
  const now = moment.utc().format();
  return now;
};

export function sortByKey(list, key) {
  return list?.sort((a, b) => {
    const nameA = a[key].toUpperCase(); // ignore upper and lowercase
    const nameB = b[key].toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0; // names must be equal
  });
}

export function objectToFormData(obj) {
  const formData = new FormData();

  // Recursive function to handle nested objects
  function appendFormData(data, formData, rootKey) {
    if (Array.isArray(data)) {
      data.forEach((value, index) => {
        appendFormData(value, formData, `${rootKey}[${index}]`);
      });
    } else if (typeof data === "object" && data !== null) {
      Object.keys(data).forEach((key) => {
        if (rootKey) {
          appendFormData(data[key], formData, `${rootKey}.${key}`);
        } else {
          appendFormData(data[key], formData, key);
        }
      });
    } else {
      // Convert boolean values to FormData as they are
      formData.append(rootKey, data);
    }
  }

  // Start appending recursively
  appendFormData(obj, formData, "");

  return formData;
}

export function convertStringToArray(inputString) {
  // Ensure inputString is a string, if not, default to an empty string
  const validString = inputString || "";

  // Check if the string contains '&'
  if (validString.includes(",")) {
    return validString
      .split(",") // Split the string by '&'
      .map((item) => item.trim()); // Trim any extra whitespace
  } else {
    // If '&' is not present, return the input string as a single-item array
    return validString ? [validString.trim()] : [];
  }
}
export function convertArrayToString(input) {
  // If input is not an array, return it as is
  if (!Array.isArray(input)) {
    return input;
  }

  // Ensure all elements in the array are strings
  if (!input.every((item) => typeof item === "string")) {
    // You might want to handle this case differently depending on your needs
    throw new Error("All elements in the array must be strings");
  }

  // Convert the array to a comma-separated string
  return input.filter((item) => item.trim()).join(",");
}
export const normalizeResponse = (response) => {
  if (!response) {
    return false;
  }
  const isMatch = (value) => {
    return (
      value === "matched" ||
      value === "Matched" ||
      value === true ||
      value === "Yes"
    );
  };
  if (response.Matched || response.Result || response?.result) {
    const matched = response.Matched || response.Result || response?.result;
    return isMatch(matched);
  }

  // Function to determine if a value indicates a match

  // Check if the response is an object with multiple categories
  return Object.keys(response).some((key) => {
    const categoryInfo = response[key];
    if (typeof categoryInfo === "object") {
      const matched = categoryInfo.Matched || categoryInfo.Result;
      return isMatch(matched);
    }
    return false;
  });
};
export function FindCommonSubstrings(str1, str2) {
  const m = str1.length;
  const n = str2.length;
  const dp = Array(m + 1)
    .fill(null)
    .map(() => Array(n + 1).fill(0));
  let maxLength = 0;
  let endIndexStr1 = 0;

  // Fill the DP table
  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      if (str1[i - 1] === str2[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1] + 1;
        if (dp[i][j] > maxLength) {
          maxLength = dp[i][j];
          endIndexStr1 = i;
        }
      } else {
        dp[i][j] = 0;
      }
    }
  }
  // Extract the longest common substring
  const startIndex = endIndexStr1 - maxLength;
  return str1.substring(startIndex, endIndexStr1);
}

export function CountWords(text) {
  // Trim the text to remove any leading or trailing whitespace
  text = text?.trim();

  // Check if the text is empty
  if (text?.length === 0) {
    return 0;
  }

  // Split the text by whitespace and filter out any empty strings
  const words = text?.split(/\s+/);

  // Return the number of words
  return words?.length;
}

export const argumentIdeas = (content) => {
  // Check if content contains numbered items
  const hasNumberedItems = /\d+\.\s/.test(content);

  // If content has numbered items, format it
  if (hasNumberedItems) {
    return content.replace(/\n/g, "<br />"); // Converts newlines into <br /> tags
  }

  // Return content as-is if no numbering is detected
  return content;
};

export const hotTopicBorder =
  "border-y-4 border-r-2  border-l-4 border-primary p-2 rounded-2xl";

export const AIIcon = () => (
  <svg
    version="1.1"
    id="Icon_Set"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 256 256"
    style={{ enableBackground: "new 0 0 256 256" }}
    xmlSpace="preserve"
  >
    <style>
      {`
        .st0 { fill: #4c4372; }
        .st2 { fill: #fd919e; }
        .st5 { fill: #fff; }
      `}
    </style>
    <circle cx="128" cy="128" r="103.5" style={{ fill: "#f0c48a" }} />
    <path
      style={{ fill: "#a4cff2" }}
      d="M56.663 127.072h141.855v82.854H56.663z"
    />
    <path
      style={{ fill: "#7babf1" }}
      d="M184.805 127.072h13.714v82.854h-13.714z"
    />
    <path
      className="st0"
      d="M198.519 212.927H56.663a3 3 0 0 1-3-3v-82.854a3 3 0 0 1 3-3h141.855a3 3 0 0 1 3 3v82.854a2.998 2.998 0 0 1-2.999 3zm-138.856-6h135.855v-76.854H59.663v76.854z"
    />
    <path
      className="st5"
      d="M128 196.887H71.195v-78.936h44.772c6.646 0 12.033 5.387 12.033 12.032v66.904zM184.805 196.887H128v-66.903c0-6.646 5.387-12.032 12.032-12.032h44.772v78.935z"
    />
    <path
      className="st0"
      d="M115.683 147.166H83.806a3 3 0 1 1 0-6h31.876a3 3 0 0 1 .001 6zM115.683 133.89H83.806a3 3 0 1 1 0-6h31.876a3 3 0 0 1 .001 6zM115.683 160.442H83.806a3 3 0 1 1 0-6h31.876a3 3 0 0 1 .001 6zM115.683 173.719H83.806a3 3 0 1 1 0-6h31.876a3 3 0 0 1 .001 6zM115.683 186.995H83.806a3 3 0 1 1 0-6h31.876a3 3 0 0 1 .001 6z"
    />
    <path
      style={{ fill: "#d3e6f8" }}
      d="M174.313 117.951h10.492v78.936h-10.492z"
    />
    <path
      className="st0"
      d="M128 199.887H71.195a3 3 0 0 1-3-3v-78.936a3 3 0 0 1 3-3h44.772c8.289 0 15.033 6.743 15.033 15.032v66.903a3 3 0 0 1-3 3.001zm-53.805-6H125v-63.903c0-4.98-4.052-9.032-9.033-9.032H74.195v72.935z"
    />
    <path
      className="st0"
      d="M184.805 199.887H128a3 3 0 0 1-3-3v-66.903c0-8.289 6.743-15.032 15.032-15.032h44.772a3 3 0 0 1 3 3v78.936a2.998 2.998 0 0 1-2.999 2.999zm-53.805-6h50.805v-72.936h-41.772c-4.98 0-9.032 4.052-9.032 9.032v63.904z"
    />
    <path
      className="st0"
      d="M172.341 147.166h-31.877a3 3 0 1 1 0-6h31.877a3 3 0 1 1 0 6zM172.341 160.442h-31.877a3 3 0 1 1 0-6h31.877a3 3 0 1 1 0 6zM172.341 173.719h-31.877a3 3 0 1 1 0-6h31.877a3 3 0 1 1 0 6zM172.341 186.995h-31.877a3 3 0 1 1 0-6h31.877a3 3 0 1 1 0 6zM172.341 133.89h-31.877a3 3 0 1 1 0-6h31.877a3 3 0 1 1 0 6z"
    />
    <circle className="st2" cx="174.312" cy="81.472" r="6.791" />
    <path
      className="st0"
      d="M174.313 91.264c-5.399 0-9.792-4.393-9.792-9.792s4.393-9.792 9.792-9.792c5.398 0 9.791 4.393 9.791 9.792s-4.393 9.792-9.791 9.792zm0-13.584c-2.091 0-3.792 1.701-3.792 3.792s1.701 3.792 3.792 3.792 3.791-1.701 3.791-3.792-1.701-3.792-3.791-3.792z"
    />
    <circle className="st2" cx="205.31" cy="99.736" r="6.791" />
    <path
      className="st0"
      d="M205.31 109.528c-5.398 0-9.791-4.393-9.791-9.792s4.393-9.792 9.791-9.792c5.399 0 9.792 4.393 9.792 9.792s-4.393 9.792-9.792 9.792zm0-13.584c-2.091 0-3.791 1.701-3.791 3.792s1.7 3.792 3.791 3.792 3.792-1.701 3.792-3.792-1.702-3.792-3.792-3.792z"
    />
    <ellipse className="st5" cx="127.591" cy="78.901" rx="10.771" ry="32.827" />
    <ellipse
      transform="scale(-1) rotate(-58.72 -140.266 226.818)"
      className="st5"
      cx="127.591"
      cy="78.901"
      rx="10.771"
      ry="32.827"
    />
    <ellipse
      transform="rotate(58.72 127.595 78.901)"
      className="st5"
      cx="127.591"
      cy="78.901"
      rx="10.771"
      ry="32.828"
    />
    <path
      className="st0"
      d="M127.591 114.729c-9.462 0-13.771-18.571-13.771-35.827s4.31-35.828 13.771-35.828c9.041 0 13.771 18.023 13.771 35.828 0 17.255-4.309 35.827-13.771 35.827zm0-65.656c-2.324 0-7.771 10.148-7.771 29.828 0 19.679 5.447 29.827 7.771 29.827s7.771-10.148 7.771-29.827c0-19.679-5.447-29.828-7.771-29.828z"
    />
    <path
      className="st0"
      d="M149.639 101.278c-7.353 0-18.268-3.966-29.199-10.608-7.758-4.714-14.478-10.17-18.921-15.363-6.605-7.72-6.118-12.424-4.546-15.01 1.046-1.722 3.381-3.772 8.571-3.772 7.352 0 18.267 3.965 29.198 10.607 14.747 8.96 28.381 22.287 23.468 30.373-1.046 1.722-3.382 3.773-8.571 3.773zm-44.096-38.754c-2.006 0-3.174.445-3.443.888-.459.757.214 3.596 3.978 7.994 4.041 4.723 10.248 9.743 17.478 14.137 9.732 5.914 19.971 9.735 26.083 9.735 2.006 0 3.174-.446 3.443-.889 1.206-1.986-4.638-11.911-21.456-22.131-9.733-5.912-19.971-9.734-26.083-9.734z"
    />
    <path
      className="st0"
      d="M105.543 101.278s.001 0 0 0c-5.189 0-7.525-2.052-8.571-3.773-1.572-2.586-2.059-7.29 4.546-15.01 4.443-5.193 11.163-10.649 18.921-15.363 10.933-6.643 21.848-10.607 29.199-10.607 5.189 0 7.525 2.051 8.57 3.772 4.913 8.086-8.721 21.413-23.468 30.373-10.928 6.643-21.844 10.608-29.197 10.608zm44.096-38.754c-6.111 0-16.35 3.821-26.083 9.734-7.229 4.394-13.437 9.414-17.478 14.137-3.764 4.398-4.437 7.237-3.978 7.994.269.442 1.438.889 3.443.889 6.112 0 16.35-3.821 26.083-9.735 16.818-10.22 22.662-20.145 21.456-22.131-.269-.442-1.437-.888-3.443-.888z"
    />
    <circle className="st2" cx="127.591" cy="78.901" r="12.362" />
    <path
      className="st0"
      d="M127.591 94.264c-8.471 0-15.362-6.892-15.362-15.362s6.892-15.362 15.362-15.362 15.362 6.892 15.362 15.362-6.891 15.362-15.362 15.362zm0-24.725c-5.163 0-9.362 4.2-9.362 9.362s4.2 9.362 9.362 9.362 9.362-4.2 9.362-9.362-4.2-9.362-9.362-9.362z"
    />
    <circle className="st0" cx="174.313" cy="120.951" r="3" />
  </svg>
);
