import React from "react";
import SubscriptionPlans from "./SubscriptionPlan";

const UpgradePlan = () => {
  return (
    <div className="min-h-screen px-3 md:px-8 py-4 w-full flex justify-center items-start">
      <SubscriptionPlans />
    </div>
  );
};

export default UpgradePlan;
