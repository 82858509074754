import React, { useRef } from "react";
import ActionButton from "../UI/Buttons/ActionButton";
import { ReactComponent as UploadCSV } from "../../Assets/Svg/UploadCSV.svg";

const CSVUploader = ({
  setDisplayContacts,
  setContacts,
  setOpenMemberSelect,
  selectedContacts,
}) => {
  const inputRef = useRef(null);
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const parsedData = parseCSV(text);
        const sortedUsers = parsedData?.filter((user) => user.email && user.first_name)
        const filterUsers = sortedUsers?.filter((user) =>
          selectedContacts?.every((contact) => contact.value !== user.email)
        ).map((user, i) => ({
            label: `${user.first_name} ${user.last_name ? user.last_name : ""}`,
            value: user.email,
            id: user.id + i + Date.now(),
          }));
        setContacts(filterUsers);
        setDisplayContacts(filterUsers);
        setOpenMemberSelect(true);
      };
      reader.readAsText(file);
    } else {
      alert("Please select a valid CSV file.");
    }
  };

  const parseCSV = (text) => {
    const lines = text.split("\n").filter((line) => line.trim()); // Split by lines
    const headers = lines[0]
      .split(",")
      .map((header) => header.trim().replace(/^"|"$/g, '')); // Clean up extra quotes from headers
    
    return lines.slice(1).map((line) => {
      const values = line.split(",").map((value) => value.trim().replace(/^"|"$/g, '')); // Remove quotes from values
      const user = {};
      headers.forEach((header, index) => {
        user[header] = values[index]; // Map headers to values without quotes
      });
      return user;
    });
  };
  

  return (
    <div>
      <ActionButton
        title={"Upload-CSV"}
        onClick={() => inputRef.current.click()}
        count={""}
        SVG={UploadCSV}
      />
      <input
        ref={inputRef}
        type="file"
        className="hidden"
        accept=".csv"
        onChange={handleFileUpload}
      />
      {/* {users.length > 0 && (
        <div>
          <h2>User Details</h2>
          <ul>
            {users.map((user, index) => (
              <li key={index}>
                <p>Name: {user.name}</p>
                <p>Email: {user.email}</p>
                {user.photo && (
                  <img src={user.photo} alt={user.name} width="50" />
                )}
              </li>
            ))}
          </ul>
        </div>
      )} */}
    </div>
  );
};

export default CSVUploader;
