import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { components } from "react-select";
import { ReactComponent as Google } from "../../Assets/Svg/Google.svg";
import { ReactComponent as SendInvitePlane } from "../../Assets/Svg/SendInvitePlane.svg";
import CSVUploader from "../../Components/Functionality/CSVUploader";
import ConatctList from "../../Components/Functionality/ConatctList";
import TagsInput from "../../Components/Functionality/TagsInput";
import ActionButton from "../../Components/UI/Buttons/ActionButton";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import { useAuth } from "../../Context/AuthContext";
import {
  useAddUserToSubWorkspaceByEmails,
  useUpdateSubWorkspace,
} from "../../Web-Hooks/Workspace/use-subworkspace";
import FeedHeader from "../../Components/Functionality/FeedHeader";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import InputField from "../../Layout/InputField";
import { useQueryClient } from "@tanstack/react-query";
import DownloadSampleCSV from "../../Utils/DownloadSampleCSV";
import { usePlan } from "../../Context/PlanContext";

const AddColleagues = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { subWorkspaceList, UpdateSubWorkSpace } = useSubWorkspace();
  const queryClient = useQueryClient();
  const [displayContacts, setDisplayContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [openMemberSelect, setOpenMemberSelect] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  const { workspaceId, subworkspaceId, ViaGoogleLoggedIn } = location.state;
  const [forumName, setForumName] = useState("");
  const findForum = subWorkspaceList?.find(
    (item) => item.subWorkSpaceId === subworkspaceId
  );
  const { mutateAsync: updateSubworkspaceName } = useUpdateSubWorkspace();
  const {currentPlan} = usePlan();

  const memberLimit = currentPlan?.maxAddUserPerForum

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (searchValue) {
        const filteredList = contacts.filter((item) =>
          item.label.toLowerCase().includes(searchValue.toLowerCase())
        );
        setDisplayContacts(filteredList);
      } else {
        setDisplayContacts(contacts);
      }
    }, 300); // Adjust the debounce delay (300ms) as needed

    return () => clearTimeout(debounceTimeout);
  }, [searchValue, contacts]);

  // const handleGoogleContact = async () => {
  //   try {
  //     if (!user || !user.accessToken) {
  //       console.error("User not authenticated or missing access token");
  //       return;
  //     }

  //     const googleContacts = new GoogleContacts({
  //       clientId: "YOUR_GOOGLE_CLIENT_ID",
  //       accessToken: user.accessToken,
  //     });

  //     const { success, contacts, error } =
  //       await googleContacts.requestPermissionsAndFetchContacts();

  //     if (success) {
  //       setContacts((prevContacts) => [
  //         ...prevContacts,
  //         ...contacts.map(({ email, title }) => ({
  //           label: email,
  //           value: title,
  //         })),
  //       ]);
  //     } else {
  //       console.error("Failed to fetch contacts:", error);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching contacts:", error);
  //   }
  // };

  /**
   * Create workspace and subworkspace with invited members from contacts
   */
  const { mutateAsync: addUserToSubWorkspace, isPending: isAddingWorkspace } =
    useAddUserToSubWorkspaceByEmails();

  const updateWorkspaceName = () => {
    const updatedSubWorkspace = {
      WorkSpaceId: workspaceId,
      SubWorkSpaceId: subworkspaceId,
      InvitedBy: user?.userId,
      SubWorkSpaceName: forumName,
    };
    updateSubworkspaceName(updatedSubWorkspace)
      .then((res) => {
        UpdateSubWorkSpace({
          ...findForum,
          subWorkSpaceName: forumName,
        });
        // UpdateSelectedSubWorkspace(subworkSpace);
      })
      .finally(() => {
        queryClient.invalidateQueries({
          queryKey: ["sub-workspaces", workspaceId],
        });
      });
  };

  const handleCreateWorkspace = async () => {
    const emailArray = selectedContacts.map(({ label, value }) => value);
    const EmailString = emailArray.join(";");

    if (!EmailString) {
      navigate("/feed", { replace: true });
      (forumName !== "" && forumName !== findForum?.subWorkSpaceName) && updateWorkspaceName();
      return;
    }

    const workspaceUser = {
      WorkSpaceId: workspaceId,
      SubWorkSpaceId: subworkspaceId,
      InvitedUserIds: EmailString,
      InvitedBy: user?.userId,
    };

    try {
      (forumName !== "" && forumName !== findForum?.subWorkSpaceName) && updateWorkspaceName();
      addUserToSubWorkspace(workspaceUser)
        .then((res) => {
          localStorage.removeItem("newWorkspace");
          navigate("/feed", { replace: true });
          toast.success("Users have been invited to the subworkspace");
        })
        .catch((error) => {
          console.error("Error creating workspace or subworkspace:", error);
        });

      // selectSubWorkspace(subWorkspace);
    } catch (error) {
      console.error("Error creating workspace or subworkspace:", error);
    }
  };

  const customStyles = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#E8F5FB",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#424242",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#424242",
      ":hover": {
        backgroundColor: "#c1e7dd",
        color: "#0a431f",
      },
    }),
  };

  const CustomMultiValue = (props) => {
    return (
      <components.MultiValue {...props}>
        <span
          style={{ display: "flex", alignItems: "center" }}
          className="gap-2 "
        >
          <SendInvitePlane className="h-4 w-4" />{" "}
          <components.MultiValueLabel {...props} children={props.children} />{" "}
          {/* Include the label */}
        </span>
      </components.MultiValue>
    );
  };

  // const [client, setClient] = useState(null);

  const requestContactAuthorization = async () => {
    try {
      const token = localStorage?.getItem("accessToken");
      if (token) {
        toast.promise(fetchContacts(token), {
          loading: "Fetching contacts...",
          success: (data) =>
            `${
              data?.otherContacts ? "Contacts fetched" : "No contacts found"
            } `,
          error: <b>Could not fetch contacts.</b>,
        });
      }
    } catch (error) {
      console.error("Error requesting contact authorization:", error);
    }
  };

  const fetchContacts = async (token) => {
    try {
      const response = await fetch(
        "https://people.googleapis.com/v1/otherContacts?readMask=names,emailAddresses",
        {
          headers: {
            "GData-Version": "3.0",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch contacts");
      }

      const contactsData = await response.json();
      if (contactsData?.otherContacts) {
        const data = contactsData.otherContacts;
        const updatedData = data.map((contact) => ({
          label: contact.emailAddresses[0].value,
          value: contact.emailAddresses[0].value,
        }));
        setContacts(updatedData);
        setDisplayContacts(updatedData);
        setOpenMemberSelect(true);
        setSearchValue("");
        return contactsData;
      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  return (
    <>
      <FeedHeader />
      <section className="flex py-2 flex-col px-8 w-full justify-evenly gap-6">
        <div className="text-3xl sm:text-5xl font-bold">
          <p> {`Who else is on the`}</p>
          <p className="text-secondary">{` Debatebase team?`}</p>
        </div>

        <div className="w-full lg:w-3/4 xl:w-1/2">
          <InputField
            isControlled={false}
            inputName="ForumName"
            value={findForum?.subWorkSpaceName}
            FieldName="Change your default forum name?"
            labeClassName={"text-2xl mb-3 font-bold"}
            FieldType="text"
            FieldSize="lg"
            placeholder="Enter your forum  name"
            onChange={(e) => setForumName(e.target.value)}
            maxLength={35}
          />
        </div>

        {openMemberSelect ? (
          <div className="  w-full lg:w-3/4 xl:w-1/2">
            <ConatctList
              displayContacts={displayContacts}
              setSearchValue={setSearchValue}
              setSelectedContactsFromProp={setSelectedContacts}
              setOpenMemberSelect={setOpenMemberSelect}
              searchValue={searchValue}
              selectedContactsFromProp={selectedContacts}
              contacts={contacts}
              maxLimit={memberLimit}
            />
          </div>
        ) : (
          <div className=" h-full items-start justify-center w-full lg:w-3/4 xl:w-1/2">
            <div className="flex flex-col sm:flex-row justify-between sm:items-center w-full my-4">
              {/* <GoogleContacts
              clientId="444992413005-oehtl7o6bsea2utklcrf8di2hjq5bmeu.apps.googleusercontent.com"
              render={(renderProps) => (
                <ActionButton
                  onClick={renderProps.onClick}
                  count={"Add from Google Contact"}
                  SVG={Google}
                  className={
                    "capitalize font-body flex items-center gap-2 text-md text-secondary font-semibold"
                  }
                />
              )}
              cookiePolicy={"single_host_origin"}
              // responseType="code,token"
              maxResults={10}
              buttonText="Import"
              onSuccess={handleGoogleContact}
              onFailure={handleGoogleContact}
            /> */}
              <p className="capitalize font-body flex items-center text-md text-debatePalette-title font-semibold">
                Upload Contact
              </p>

              <div className="flex self-end gap-3 justify-center">
                {ViaGoogleLoggedIn && (
                  <ActionButton
                    title={"Add-from-Google-Contact"}
                    onClick={requestContactAuthorization}
                    SVG={Google}
                    count={""}
                  />
                )}
                <CSVUploader
                  selectedContacts={selectedContacts}
                  setSearchValue={setSearchValue}
                  setDisplayContacts={setDisplayContacts}
                  setContacts={setContacts}
                  setOpenMemberSelect={setOpenMemberSelect}
                />
                <DownloadSampleCSV />
              </div>
            </div>
            <TagsInput
              contacts={selectedContacts}
              setContacts={setSelectedContacts}
              selectClass={"w-full max-h-32 h-32 !items-start my-4"}
              customComponent={CustomMultiValue}
              customStyles={customStyles}
              maxLimit={memberLimit}
            />
            <div className="flex justify-end items-center w-full my-4">
              {/* <BasicButton
              variant={"outlined"}
              className={"bg-transparent "}
              onClick={() => signOut(auth)}
            >
              Copy Invitation Link
            </BasicButton> */}
              <BasicButton
                id={"create-workspace"}
                loading={isAddingWorkspace}
                color={"primary"}
                className={"px-5"}
                onClick={() => handleCreateWorkspace()}
              >
                Create
              </BasicButton>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default AddColleagues;
