import { Button } from "@material-tailwind/react";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import NoData from "../../../Components/Functionality/NoData";
import InfoPopup from "../../../Components/Popup/InfoPopup";
import LoadingSpinner from "../../../Components/UI/LoadingSpinner";
import { useAuth } from "../../../Context/AuthContext";
import { SocketEvents, useSocket } from "../../../Context/SocketContext";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../../Context/WorkspaceContext";
import { isTodayOrYesterday } from "../../../Utils/Helper";
import { useGetChatHistory } from "../../../Web-Hooks/Chat/chat";
import { useDeleteBlob } from "../../../Web-Hooks/LibraryAPI/use-citation";
import TitleDivider from "../TitleDivider";
import MessageCard from "./MessageCard";

const InfiniteScrollMessageScreen = ({ user }) => {
  const messagesEndRef = useRef(null);
  const { user: loggedInUser } = useAuth();
  const [chat, setChat] = useState([]);
  const [typeState, setTypeState] = useState(false);
  const { socket, sendMessage, deleteMessage } = useSocket();
  const [size] = useState(100);
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();
  const [selectedMessage, setSelectedMessage] = useState(null);
  const isForumOnly = process.env.REACT_APP_FORUM_WISE === "true";
  const { mutateAsync: deleteBlob } = useDeleteBlob();

  const {
    data,
    isLoading: isChatLoading,
  } = useGetChatHistory(
    loggedInUser?.userId,
    user?.userId,
    selectedWorkspace?.workSpaceId,
    size,
    isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : ""
  );

  const chatHistory = useMemo(() => {
    return data?.pages?.flatMap((page) => page.data.data.messages).reverse();
  }, [data]);

  useEffect(() => {
    setChat(chatHistory);
  }, [chatHistory]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  }, [chat]);

  useEffect(() => {
    if (!socket) return;
    // to check user is typing or not
    socket.on("userTyping", (data) => {
      setTypeState(data);
    });

    // to check if receiver has read the message and update chat from sender side
    socket.on(
      SocketEvents.MARKED_AS_READ,
      ({ senderId, receiverId, workspaceId, subworkspaceId }) => {
        if (
          workspaceId !== selectedWorkspace?.workSpaceId ||
          subworkspaceId !==
            (isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : "")
        )
          return;

        if (loggedInUser?.userId === receiverId) {
          setChat((prevChat) =>
            prevChat?.map((message) => {
              if (message.senderId === receiverId) {
                return { ...message, isRead: true };
              }
              return message;
            })
          );
        }
      }
    );

    // to remove deleted message
    socket.on("messageDeleted", ({ messageId }) => {
      setChat((prevChat) =>
        prevChat?.filter((messages) => messages.id !== messageId)
      );
    });
  }, [
    socket,
    loggedInUser,
    selectedWorkspace,
    selectedSubWorkspace,
    isForumOnly,
  ]);

  useEffect(() => {
    if (!socket) return;
    const handleReceiveMessage = (message) => {
      if (
        message.workspaceId !== selectedWorkspace?.workSpaceId ||
        message.subworkspaceId !==
          (isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : "")
      )
        return;

      setChat((prevChat) => [...prevChat, message]);
      if (message.receiverId === loggedInUser?.userId) {
        socket.emit(SocketEvents.MARK_ALL_AS_READ, {
          senderId: loggedInUser?.userId,
          receiverId: user?.userId,
          workspaceId: selectedWorkspace?.workSpaceId,
          subworkspaceId: isForumOnly
            ? selectedSubWorkspace?.subWorkSpaceId
            : "",
        });
      }
      messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
    };

    // to receive message
    socket.on("receiveMessage", handleReceiveMessage);
    return () => {
      socket.off("receiveMessage", handleReceiveMessage);
    };
  }, [
    socket,
    chat,
    loggedInUser,
    user,
    selectedWorkspace,
    selectedSubWorkspace,
    isForumOnly,
  ]);

  const handleSend = () => {
    const Message = {
      type: "text",
      content: {
        text: "Hey there",
      },
    };

    sendMessage(
      loggedInUser?.userId,
      user?.userId,
      Message,
      selectedWorkspace?.workSpaceId,
      isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : ""
    );
  };

  if (isChatLoading) {
    return <LoadingSpinner />;
  }

  const handleDeleteMessage = async () => {
    const { content, senderId } = selectedMessage;
    const typeToDelete = ["video", "pdf", "doc", "image"];
    if (typeToDelete?.includes(content?.type)) {
      await deleteBlob({ fileUrl: content?.content?.url });
    }
    await deleteMessage(senderId, user?.userId, selectedMessage.id);

    setSelectedMessage(null);
  };

  return (
    <div className="h-full w-full overflow-y-auto  pb-3 px-6">
      <InfoPopup
        title={"Delete Message"}
        description={
          "Action will delete message from the group no user will be able to see this message"
        }
        onClickBtn1={handleDeleteMessage}
        onClickBtn2={() => setSelectedMessage(null)}
        handleClose={() => setSelectedMessage(null)}
        btn1={"Delete"}
        btn2={"Cancel"}
        open={!!selectedMessage}
      />

      <section
        className={`${
          chatHistory?.length > 0 ? "min-h-full" : "h-full"
        } w-full flex flex-col justify-end`}
      >
        {chat?.length > 0 ? (
          chat?.map((message, index) => (
            <React.Fragment key={index}>
              {message && (
                <>
                  {index === 0 ||
                  !moment(message?.timestamp).isSame(
                    chat[index - 1]?.timestamp,
                    "day"
                  ) ? (
                    <TitleDivider
                      title={isTodayOrYesterday(message?.timestamp)}
                    />
                  ) : null}
                </>
              )}

              <MessageCard
                setSelectedMessage={setSelectedMessage}
                receiver={user}
                message={message}
                key={message.id}
              />
            </React.Fragment>
          ))
        ) : (
          <React.Fragment key="hello">
            <div className="my-8 h-full">
              <NoData
                size={"h-[80%] w-full"}
                children={
                  <Button
                    className="bg-secondary shrink-0"
                    onClick={handleSend}
                  >
                    Say Hello
                  </Button>
                }
                message="No messages yet"
              />{" "}
            </div>
          </React.Fragment>
        )}

        {typeState.isTyping && typeState.userId === user?.userId && (
          <div className="flex items-end justify-start gap-4 my-2">
            <span className="text-md font-semibold text-secondary">
              <span className="capitalize">{user?.username}</span> is typing
            </span>
            <ThreeDots
              visible={true}
              height={"20px"}
              width={"20px"}
              color={"#153C58"}
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}
        <div ref={messagesEndRef} />
      </section>
    </div>
  );
};

export default InfiniteScrollMessageScreen;
