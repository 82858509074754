import { Button, Typography } from "@material-tailwind/react";
import {
  ExpressCheckoutElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "../../Context/AuthContext";
import { useCreateSubscription } from "../../Web-Hooks/Subscription/use-subscription";
import moment from "moment";

const CheckOutPage = ({ amount, clientSecret, price, subscribedPlan }) => {
  const [search] = useSearchParams();
  const location = useLocation();
  const { features } = location.state;
  const plan = search.get("plan");
  const planId = search.get("planId");
  const type = search.get("type");
  const reactStripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const { mutateAsync: createSubscription } = useCreateSubscription();
  const { user: loggedInUser } = useAuth();

  const onClick = ({ resolve }) => {
    const options = {
      emailRequired: true,
    };
    resolve(options);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if Stripe or elements are not available
    if (!reactStripe || !elements) return;

    // Attempt to submit the payment elements
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      setLoading(false);
      return;
    }

    // Confirm the setup intent with Stripe
    const { setupIntent: paymentIntent, error } =
      await reactStripe.confirmSetup({
        elements,
        clientSecret: clientSecret,
        redirect: "if_required",
        confirmParams: {
          return_url: `${window.location.origin}/payment-success?amount=${amount}&plan=${plan}&type=${type}`,
          payment_method_data: {
            allow_redisplay: "always",
          },
        },
      });

    // Handle any errors from confirming the setup intent
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error.message}`,
      });
      setErrorMessage(error.message);
      setLoading(false);
      return;
    }

    // Process the payment intent status
    switch (paymentIntent.status) {
      case "succeeded":
        // Create a subscription if the payment succeeded
        const data = await createSubscription({
          customerEmail: loggedInUser?.email,
          customerName: loggedInUser?.userName,
          priceId: price.priceId,
          isTrialPlanAvailable: subscribedPlan?.isTrialPlanAvailable,
        });

        if (data?.error) {
          setErrorMessage(data?.error?.message);
          setLoading(false);
          return;
        }

        const { subscriptionId, message } = data;
        if (!subscriptionId) {
          setErrorMessage(message);
          setLoading(false);
          return;
        }

        navigate(
          `/payment-success?amount=${amount}&plan=${plan}&type=${type}&planId=${planId}`,
          {
            state: { amount, plan, type },
            replace: true,
          }
        );
        break;

      case "canceled":
        setErrorMessage(paymentIntent.payment_method);
        navigate(
          `/payment-failed?amount=${amount}&plan=${plan}&type=${type}&planId=${planId}`,
          {
            state: { amount, plan, type },
            replace: true,
          }
        );
        break;
      case "processing":
        setErrorMessage(paymentIntent.payment_method);
        break;

      case "payment_failed":
        setErrorMessage(paymentIntent.payment_method);
        navigate(
          `/payment-failed?amount=${amount}&plan=${plan}&type=${type}&planId=${planId}`,
          {
            state: { amount, plan, type },
            replace: true,
          }
        );
        break;

      default:
        setErrorMessage("Unexpected payment status");
        break;
    }

    setLoading(false);
  };

  const newFeatures = features?.filter((feature) => feature?.included);

  if (!reactStripe || !elements) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid md:grid-cols-1 lg:grid-cols-[1fr_45%] gap-2 w-full items-center justify-center">
      <div className="order-2 lg:order-1 rounded-r-lg flex flex-col justify-start h-full bg-gray-50 p-3">
        <div className="px-14">
          <h3 className="text-2xl font-bold text-debatePalette-title">
            Subscription Summary
          </h3>
          <div className="flex mt-4 flex-col gap-3">
            <Typography
              variant="small"
              className="font-medium text-debatePalette-title"
            >
              You will be charged after your free trial ends. Cancel anytime
              before the trial period expires to avoid charges.
            </Typography>
            <div className="flex justify-between text-gray-600 text-base">
              <span className="text-debatePalette-title font-semibold">
                Plan Name:
              </span>
              <span className="text-debatePalette-title font-semibold">
                {plan}
              </span>
            </div>
            <div className="flex justify-between text-gray-600 text-base">
              <span className="text-debatePalette-title font-semibold">
                Next Billing Date:
              </span>
              <span className="text-debatePalette-title font-semibold">
                {moment().add(14, "days").format("MMMM D, YYYY")}
              </span>
            </div>
            <div className="flex justify-between text-gray-600 text-base">
              <span className="text-debatePalette-title font-semibold">
                Amount Due After Trial:
              </span>
              <span className="text-debatePalette-title font-semibold">
                ${amount}
              </span>
            </div>
            <div className="mt-6 ">
              <h4 className="text-debatePalette-title font-bold text-2xl">
                New Features:
              </h4>
              <ul className="mt-4 grid grid-cols-2 gap-1 space-y-3 text-base text-debatePalette-title">
                {newFeatures?.map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <span className="inline-block w-3 h-3 mr-2 bg-green-500 rounded-full text-debatePalette-title"></span>
                    {feature?.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <form
        className="order-1 lg:order-2 flex flex-col w-full gap-2 p-3"
        onSubmit={handleSubmit}
      >
        <div id="checkout-page">
          <ExpressCheckoutElement
            options={{
              buttonTheme: {
                applePay: "black",
                googlePay: "black",
              },
              buttonType: {
                applePay: "subscribe",
                googlePay: "subscribe",
              },
              wallets: {
                applePay: "always",
                googlePay: "always",
              },
            }}
            onConfirm={handleSubmit}
            onClick={onClick}
            className="w-full"
          />
          <PaymentElement
            className="w-full"
            options={{
              terms: {
                card: "always",
                applePay: "always",
                googlePay: "always",
              },
            }}
          />
        </div>
        {errorMessage && (
          <div className="text-debatePalette-timer">{errorMessage}</div>
        )}
        <Button
          className="flex justify-center items-center bg-primary text-secondary"
          disabled={loading || !reactStripe}
          type="submit"
          loading={loading}
        >
          {loading
            ? "Processing..."
            : `Subscribe ${
                subscribedPlan?.isTrialPlanAvailable && "Free Trial"
              }`}
        </Button>
      </form>
    </div>
  );
};

export default CheckOutPage;

// import {
//   useElements,
//   useStripe,
//   PaymentElement,
//   PaymentRequestButtonElement,
// } from "@stripe/react-stripe-js";
// import React, { useEffect, useState } from "react";
// import { Button } from "@material-tailwind/react";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import { useAuth } from "../../Context/AuthContext";
// import { useQueryClient } from "@tanstack/react-query";

// const CheckOutPage = ({ amount, price, product, clientSecret }) => {
//   const [search] = useSearchParams();
//   const plan = search.get("plan");
//   const type = search.get("type");
//   const reactStripe = useStripe();
//   const elements = useElements();
//   const navigate = useNavigate();
//   const { user: loggedInUser } = useAuth();
//   const [errorMessage, setErrorMessage] = useState();
//   const [loading, setLoading] = useState(false);
//   const [paymentRequest, setPaymentRequest] = useState(null);
//   const queryClient = useQueryClient();

//   useEffect(() => {
//     const initPaymentRequest = async () => {
//       if (!reactStripe) {
//         console.error("Stripe initialization failed.");
//         return;
//       }

//       const pr = reactStripe.paymentRequest({
//         country: "US",
//         currency: "usd",
//         total: {
//           label: `Plan: ${plan}`,
//           amount: price?.priceAmount, // Amount in cents
//         },
//         requestPayerName: true,
//         requestPayerEmail: true,
//         wallets: ["browserCard", "applePay", "googlePay", "link"],
//       });

//       pr.canMakePayment().then((result) => {
//         console.log("Can Make Payment Result:", result);
//         if (result) {
//           setPaymentRequest(pr);
//         } else {
//           console.warn("No supported payment methods available.");
//         }
//       });
//     };

//     initPaymentRequest();
//   }, [reactStripe, amount, plan]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     if (!reactStripe || !elements) return;

//     const { error: submitError } = await elements.submit();

//     if (submitError) {
//       setErrorMessage(submitError.message);
//       setLoading(false);
//       return;
//     }

//     const { paymentIntent, error } = await reactStripe.confirmPayment({
//       elements,
//       clientSecret: clientSecret,
//       redirect: "if_required",
//       confirmParams: {
//         return_url: `${window.location.origin}/payment-success?amount=${amount}&plan=${plan}&type=${type}`,
//         receipt_email: loggedInUser?.email,
//       },
//     });

//     if (error) {
//       setErrorMessage(error.message);
//       setLoading(false);
//       return;
//     }

//     if (paymentIntent.status === "succeeded") {
//       await queryClient.invalidateQueries({
//         queryKey: ["subscription"],
//       });
//       navigate(`/payment-success?amount=${amount}&plan=${plan}&type=${type}`, {
//         state: { amount, plan, type },
//         replace: true,
//       });
//       setLoading(false);
//       return;
//     }

//     if (paymentIntent.status === "canceled") {
//       setErrorMessage(paymentIntent.payment_method);
//       console.log("Payment canceled");

//       // navigate(`/payment-success?amount=${amount}&plan=${plan}&type=${type}`, {
//       //   state: { amount, plan, type },
//       //   replace: true,
//       // });
//       // setLoading(false);
//       return;
//     }

//     if (paymentIntent.status === "processing") {
//       console.log("Payment processing...");

//       // setErrorMessage(paymentIntent.payment_method);
//       // navigate(`/payment-failed?amount=${amount}&plan=${plan}&type=${type}`, {
//       //   state: { amount, plan, type },
//       //   replace: true,
//       // });
//       // setLoading(false);
//       return;
//     }

//     if (paymentIntent.status === "payment_failed") {
//       setErrorMessage(paymentIntent.payment_method);
//       navigate(`/payment-failed?amount=${amount}&plan=${plan}&type=${type}`, {
//         state: { amount, plan, type },
//         replace: true,
//       });
//       setLoading(false);
//       return;
//     }

//     setLoading(false);
//   };

//   if (!reactStripe || !elements) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <form onSubmit={handleSubmit} className="flex flex-col w-full gap-2">
//       {paymentRequest ? (
//         <div className="mb-4">
//           <PaymentRequestButtonElement options={{ paymentRequest }} />
//         </div>
//       ) : null}
//       <PaymentElement className="w-full" />
//       {errorMessage && (
//         <div className="text-debatePalette-timer">{errorMessage}</div>
//       )}
//       <Button
//         className="flex justify-center"
//         disabled={loading || !reactStripe}
//         type="submit"
//         loading={loading}
//       >
//         {loading ? "Processing..." : `Pay ${amount}`}
//       </Button>
//     </form>
//   );
// };

// export default CheckOutPage;
