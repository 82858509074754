import React, { useState } from "react";
import GoogleContacts from "./GoogleContacts";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Spinner } from "@material-tailwind/react";
import CSVContacts from "./CSVContacts";
import TagsInput from "../../Components/Functionality/TagsInput";
import { usePlan } from "../../Context/PlanContext";
import { components } from "react-select";
import InputField from "../../Layout/InputField";
import { ReactComponent as SendInvitePlane } from "../../Assets/Svg/SendInvitePlane.svg";
import useDebounce from "../../Services/useDebounce";
import { useValidateSpaceName } from "../../Web-Hooks/use-validation";
import Container from "../../Components/UI/Container";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import { useCreateWorkspace } from "../../Web-Hooks/Workspace/use-workspace";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useAuth } from "../../Context/AuthContext";
import { getInitials } from "../../Utils/Helper";

const AddWorkspace = () => {
  const [workspace, setWorkspace] = useState({
    workspaceName: "",
    forumName: "",
  });
  const { user } = useAuth();
  const ViaGoogleLoggedIn = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const deferredWorkspaceName = useDebounce(workspace?.workspaceName, 600);
  const { data: isValidWorkspaceName, isLoading: isValidatingWorkspaceName } =
    useValidateSpaceName("Work", deferredWorkspaceName);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const { currentPlan } = usePlan();
  const { mutateAsync: createWorkspace, isPending: isAddingWorkspace } =
    useCreateWorkspace();
  const memberLimit = currentPlan?.maxAddUserPerForum;
  const customStyles = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#E8F5FB",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#424242",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#424242",
      ":hover": {
        backgroundColor: "#c1e7dd",
        color: "#0a431f",
      },
    }),
  };

  const CustomMultiValue = (props) => {
    return (
      <components.MultiValue {...props}>
        <span
          style={{ display: "flex", alignItems: "center" }}
          className="gap-2 "
        >
          <SendInvitePlane className="h-4 w-4" />{" "}
          <components.MultiValueLabel {...props} children={props.children} />{" "}
          {/* Include the label */}
        </span>
      </components.MultiValue>
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Replace spaces with dashes only if the input name is "WorkSpaceName"
    const processedValue =
      name === "workspaceName" ? value.replace(/\s+/g, "-") : value;

    // Update the state based on the input name
    setWorkspace((prevData) => ({
      ...prevData,
      [name]: processedValue,
    }));
  };

  const handleCreateWorkspace = async () => {
    try {
      const emailArray = selectedContacts?.map(({ label, value }) => value);
      const workspaceData = {
        userId: user?.userId,
        workspaceName: workspace?.workspaceName,
        subWorkspaceName: workspace?.forumName,
        workSpaceInitials: getInitials(workspace?.workspaceName),
        invitedUsersEmail: emailArray,
      };
      const response = await createWorkspace(workspaceData);
      if (response?.error) {
        toast.error(response?.error?.message);
        return;
      }

      toast?.success(response?.data?.message);

      localStorage.removeItem("newWorkspace");
      navigate("/feed", { replace: true });
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Container>
      <div className="w-full h-full lg:w-3/4 xl:w-1/2 flex flex-col gap-3">
        <InputField
          maxLength={35}
          className={"font-medium"}
          isControlled={true}
          inputName="workspaceName"
          value={workspace?.workspaceName}
          FieldName="What's your debatebase name?"
          labeClassName={"text-2xl mb-3 font-bold"}
          FieldType="text"
          FieldSize="lg"
          placeholder="Enter your debatebase name"
          onChange={handleChange}
          icon={
            isValidatingWorkspaceName ? (
              <Spinner />
            ) : (
              isValidWorkspaceName && <CheckIcon className="font-bold" />
            )
          }
          isError={
            isValidatingWorkspaceName
              ? false
              : !isValidWorkspaceName && deferredWorkspaceName !== ""
          }
          errorMessage={
            isValidatingWorkspaceName
              ? ""
              : !isValidWorkspaceName &&
                deferredWorkspaceName !== "" &&
                "debatebase name already exists"
          }
        />
        <hr className="border-debatePalette-buttonBorder my-2" />

        <InputField
          isControlled={true}
          inputName="forumName"
          value={workspace?.forumName}
          FieldName="Change your default forum name?"
          labeClassName={"text-2xl mb-3 font-bold"}
          FieldType="text"
          FieldSize="lg"
          placeholder="Enter your forum name"
          onChange={handleChange}
          maxLength={35}
        />
        <hr className="border-debatePalette-buttonBorder my-2" />
        <div>
          <div className="flex flex-col sm:flex-row justify-between sm:items-center w-full">
            <p className="capitalize font-body flex items-center text-2xl text-debatePalette-title font-semibold">
              Upload Contact
            </p>

            <div className="flex self-end gap-3 justify-center">
              {ViaGoogleLoggedIn && (
                <GoogleContacts
                  selectedMembers={selectedContacts}
                  setSelectedMembers={setSelectedContacts}
                />
              )}
              <CSVContacts
                selectedMembers={selectedContacts}
                setSelectedMembers={setSelectedContacts}
              />
            </div>
          </div>
          <TagsInput
            contacts={selectedContacts}
            setContacts={setSelectedContacts}
            selectClass={"w-full max-h-32 h-32 !items-start my-4"}
            customComponent={CustomMultiValue}
            customStyles={customStyles}
            maxLimit={memberLimit}
          />
        </div>

        <div className="flex w-full justify-between">
          <BasicButton
            id={"create-workspace-next"}
            loading={isAddingWorkspace}
            color={"primary"}
            isDisable={!isValidWorkspaceName || !workspace?.forumName}
            size={"md"}
            className={"w-36 flex justify-center text-sm"}
            children={"Next"}
            onClick={handleCreateWorkspace}
          />

          <BasicButton
            id={"create-workspace-back"}
            color={"secondary"}
            variant={"outlined"}
            size={"md"}
            className={"w-36 flex justify-center text-secondary"}
            children={"Back"}
            onClick={() => navigate("/createworkspace")}
          />
        </div>
      </div>
    </Container>
  );
};

export default AddWorkspace;
