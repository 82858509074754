import { Button } from "@material-tailwind/react";
import moment from "moment";
import React from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { useAuth } from "../../Context/AuthContext";
import { usePlan } from "../../Context/PlanContext";
import {
  convertEpochToLocalTime,
  convertSubCurrency,
  convertSubcurrencyToMain,
} from "../../Utils/Helper";
import {
  useCreateSubscription,
  useGetProductDetails,
  useGetUpcomingInvoiceDetails,
} from "../../Web-Hooks/Subscription/use-subscription";
import PaymentMethods from "./ChangePaymentMethod";
import RenderCalculationUI from "./RenderCalculationUI";

const UpdateCheckOutPage = () => {
  const [search] = useSearchParams();
  const amount = search.get("amount");
  const location = useLocation();
  const { features, currentPlanName } = location.state;
  const { subscribedPlan } = usePlan();
  const plan = search.get("plan");
  const type = search.get("type");
  const planId = search.get("planId");
  const { data: products } = useGetProductDetails(planId, type);
  const { user: loggedInUser } = useAuth();
  const findProduct = products?.find((product) => product.productName === plan);
  const findPrice = findProduct?.prices?.find(
    (price) => price.recurringInterval === type
  );

  const stripeSubscription = subscribedPlan?.stripeJson;

  const { mutateAsync: createSubscription, isPending: isUpdating } =
    useCreateSubscription();
  const { data, isLoading } = useGetUpcomingInvoiceDetails(
    stripeSubscription?.customer,
    findPrice?.priceId,
    stripeSubscription?.items.data[0].id,
    stripeSubscription?.id
  );
  const nextBillingCycleDate = moment()
    .add(type === "month" ? 1 : 12, type)
    .format("MMMM D, YYYY");

  const navigate = useNavigate();
  const newFeatures = features?.filter((feature) => feature?.included);
  const billingPeriodEndDate = moment(
    convertEpochToLocalTime(data?.upcomingInvoice?.lines?.data[1]?.period?.end)
  ).format("MMMM D, YYYY");
  const nextPeriodStartDate =  moment(
    convertEpochToLocalTime(
      stripeSubscription?.current_period_end
    )
  ).format("MMMM D, YYYY");

  const isDownGrade = data?.isPlanDownGraded;

  const handleSubmit = async () => {
    try {
      await createSubscription({
        customerEmail: loggedInUser?.email,
        customerName: loggedInUser?.userName,
        priceId: findPrice.priceId,
      }).then((res) =>
        res.status === "active"
          ? navigate(
              `/payment-success?amount=${amount}&plan=${plan}&type=${type}&planId=${planId}`,
              {
                state: { amount, plan, type },
                replace: true,
              }
            )
          : navigate(
              `/payment-failed?amount=${amount}&plan=${plan}&type=${type}&planId=${planId}`,
              {
                state: { amount, plan, type },
                replace: true,
              }
            )
      );
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const renderDownGradeUI = () => {
    return (
      <div className="flex flex-col gap-6 items-stretch justify-center">
        {stripeSubscription?.cancel_at_period_end && (
          <p className="text-base  font-normal text-debatePalette-title text-pretty">
            Your subscription is currently scheduled to cancel on{" "}
            {moment(
              convertEpochToLocalTime(stripeSubscription?.current_period_end)
            ).format("MMMM D, YYYY")}
            . By confirming, your updated subscription will be renewed with the
            following details.
          </p>
        )}
        <p className="text-base font-normal text-debatePalette-title">
          Your subscription will be updated at the end of your current billing
          period on {billingPeriodEndDate}. You will have access to your current
          subscription's features until then.
        </p>
        <div className="grid grid-cols-[1fr_auto] gap-3 text-debatePalette-title text-base font-semibold">
          <p>What you'll pay monthly starting</p>
          <p>${amount}</p>
          <p>{billingPeriodEndDate}</p>
          {/* <PencilSquareIcon className="h-6 w-6" /> */}
        </div>
        <Button
          className="bg-secondary"
          loading={isUpdating}
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </div>
    );
  };

  const renderUpGradeUI = () => {
    return (
      <div className="flex flex-col gap-6 items-stretch justify-center">
        {stripeSubscription?.cancel_at_period_end && (
          <p className="text-base  font-normal text-debatePalette-title text-pretty">
            Your subscription is currently scheduled to cancel on{" "}
            {moment(
              convertEpochToLocalTime(stripeSubscription?.current_period_end)
            ).format("MMMM D, YYYY")}
            . By confirming, your updated subscription will be renewed with the
            following details.
          </p>
        )}
        <div className="grid grid-cols-[1fr_auto] gap-1 text-debatePalette-title text-base font-semibold">
          <p className="text-base font-normal">
            What you'll pay monthly starting
          </p>
          <p className="text-base font-normal">
            {convertSubcurrencyToMain(
              stripeSubscription?.cancel_at_period_end ? stripeSubscription?.currency : data?.upcomingInvoice?.currency,
              convertSubCurrency(amount)
            )}
          </p>
          <p className="text-base font-normal">{nextBillingCycleDate}</p>
          {/* <PencilSquareIcon className="h-6 w-6" /> */}
        </div>

        <hr className="bg-gray-500 border-debatePalette-hr border-1 dark:bg-gray-700" />
        {!stripeSubscription?.cancel_at_period_end && (
          <RenderCalculationUI
            upcomingInvoice={data?.upcomingInvoice}
            oldPlan={{
              planName: currentPlanName,
              priceId: stripeSubscription?.items?.data[0]?.plan?.id,
            }}
            newPlan={{
              planName: plan,
              priceId: findPrice?.priceId,
            }}
          />
        )}
        <div className="grid grid-cols-[1fr_auto] gap-1 text-debatePalette-title text-base font-semibold">
          <p className="text-base font-semibold">Amount due today</p>
          <p className="text-lg font-bold">
            {convertSubcurrencyToMain(
              stripeSubscription?.cancel_at_period_end ? stripeSubscription?.currency : data?.upcomingInvoice?.currency,
              stripeSubscription?.cancel_at_period_end ? convertSubCurrency(amount) : data?.upcomingInvoice?.amount_due
            )}
          </p>
        </div>
        <Button
          className="bg-primary text-secondary flex justify-center gap-3 items-center text-[14px] font-bold"
          loading={isUpdating}
          onClick={handleSubmit}
        >
          Confirm and pay
        </Button>
        <div className="">
          <h4 className="text-debatePalette-title font-semibold text-lg">
            New Features:
          </h4>
          <ul className="mt-4 grid grid-cols-2 gap-1 space-y-3 text-base text-debatePalette-title">
            {newFeatures?.map((feature, index) => (
              <li key={index} className="flex items-center">
                <span className="inline-block w-3 h-3 mr-2 bg-secondary rounded-full text-debatePalette-title"></span>
                {feature?.text}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const defaultMethod = stripeSubscription?.default_payment_method;

  if (isLoading || !findPrice) {
    return (
      <div className="min-h-screen flex flex-col gap-2 items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="min-h-screen p-4 flex flex-col gap-2 items-center justify-start">
      <h3 className="text-5xl font-bold text-debatePalette-title">
        Confirm your updates
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="  rounded-lg flex flex-col justify-start h-full max-w-xl bg-gray-50 p-3">
          <div className="px-4">
            <div className="flex flex-col gap-6">
              <div className="flex justify-between text-gray-600  text-lg">
                <span className="text-debatePalette-title text-4xl font-semibold">
                  {plan}
                </span>
              </div>
              {isDownGrade ? renderDownGradeUI() : renderUpGradeUI()}
            </div>
          </div>
        </div>
        <div className="  rounded-lg flex flex-col justify-start h-full max-w-xl bg-gray-50 p-3">
          {
            <PaymentMethods
              customerId={stripeSubscription?.customer}
              defaultMethod={defaultMethod}
              subscriptionId={stripeSubscription?.id}
              clientSecret={data?.clientSecret}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default UpdateCheckOutPage;
