import { Tab, Tabs, TabsHeader, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useTab } from "../../Context/TabContext";
import { NotificationTabs } from "../../Utils/Constant";
import { capitalizeFirstLetter } from "../../Utils/Helper";
import EditSubDebatePopup from "../Popup/EditSubDebatePopup";
import WorkspaceStack from "../UI/AvatarStack/WorkSpaceStack";
import { handleApiTitle } from "../../Utils/HeaderNaming";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { ThreeDots } from "react-loader-spinner";

export default function FeedHeader({
  apiType = "",
  title,
  isButton,
  isTab,
  btnChildren,
  userName,
}) {
  const [apiTitle, setApiTitle] = useState("");
  const { tab, setTab } = useTab();
  const [openPopup, setOpenPopup] = useState(false);
  const { SubWorkspaceUser, SubWorkspaceUserLoading, selectedSubWorkspace } = useSubWorkspace();

  const handleTab = (value) => {
    setTab(value);
  };

  useEffect(() => {
    if (userName) {
      setApiTitle(userName);
      return;
    }
    const title = handleApiTitle(apiType);
    setApiTitle(title); // eslint-disable-next-line
  }, [apiType]);

  useEffect(() => {
    isTab && document.getElementById("nomination").click();
  }, [isTab]);

  return (
    <div className="sticky top-0 bg-white z-100 w-full">
      <div className="flex justify-between items-center px-3 md:px-8 h-12 md:h-16">
        {apiType === "/notification" ? (
          <div className="w-full sm:w-96">
            {NotificationTabs.length > 0 ? (
              <Tabs value={tab}>
                <TabsHeader
                  className={` bg-white  p-0`}
                  indicatorProps={{
                    className: `shadow-none !text-black bg-transparent`,
                  }}
                >
                  {NotificationTabs.map((opt, i) => (
                    <Tab
                      value={opt.value}
                      key={i}
                      id={opt?.value}
                      onClick={() => handleTab(opt?.value)}
                      className={`border border-gray-500 ${
                        tab === opt.value &&
                        "bg-primary rounded-md font-bold border-primary"
                      } ${
                        NotificationTabs?.length - 1 === i &&
                        "rounded-none rounded-br-3xl "
                      } ${i === 0 && "rounded-none  rounded-tl-3xl "}`}
                    >
                      {opt.label}
                    </Tab>
                  ))}
                </TabsHeader>
              </Tabs>
            ) : null}
          </div>
        ) : (
          <>
            <Typography variant="h4" className="text-2xl ">
              {title ? title : capitalizeFirstLetter(apiTitle)}
            </Typography>
            {isButton && btnChildren}
            {!isButton && selectedSubWorkspace && (
              <div
                className={`max-w-fit flex gap-2 ${
                  SubWorkspaceUser?.length === 0
                    ? "hidden"
                    : "border items-center border-debatePalette-buttonBorder"
                } p-1  md:p-1.5 rounded-md cursor-pointer`}
                onClick={() => setOpenPopup(true)}
              >
                {SubWorkspaceUserLoading ? (
                  <ThreeDots color="#F8C800" height={30} width={40} />
                ) : (
                  <>
                    {" "}
                    <WorkspaceStack Member={SubWorkspaceUser} />{" "}
                    <p className="text-sm md:text-lg">
                      {SubWorkspaceUser?.length}
                    </p>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <hr className=" bg-gray-500 border-debatePalette-hr border-1 dark:bg-gray-700" />
      <EditSubDebatePopup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
      />
    </div>
  );
}
