import { Button, Radio } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  useGetPaymentMethods,
  useSetDefaultPaymentMethod
} from "../../Web-Hooks/Subscription/use-subscription";
import PaymentMethodForm from "./AddPaymentMethod";
import { PaymentMethodCard } from "./PaymentMethodCard";

const PaymentMethods = ({ clientSecret,customerId,defaultMethod, subscriptionId }) => {
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
  const { data: paymentMethods, isLoading: isPaymentMethodsLoading } =
    useGetPaymentMethods(customerId);
  const [selectPaymentMethod, setSelectPaymentMethod] = useState(null);
  const { mutateAsync: setDefaultPaymentMethod, isPending: isUpdating } =
    useSetDefaultPaymentMethod();
    
  useEffect(() => {
    setSelectPaymentMethod(defaultMethod);
  }, [defaultMethod]);

  const handleSetDefaultPaymentMethod = async () => {
    if (selectPaymentMethod === "AddNewMethod") {
      return;
    }
    try {
      const res = await setDefaultPaymentMethod({
        customerId: customerId,
        subscriptionId: subscriptionId,
        paymentMethodId: selectPaymentMethod,
      });
      if (res) {
        toast?.success("Payment method updated successfully");
      }
    } catch (error) {
      toast?.error(error.message);
    }
  };

  if (isPaymentMethodsLoading) {
    return null;
  }

  const handleSetPaymentMethod = (id) =>{
    setShowAddPaymentMethod(false);
    setSelectPaymentMethod(id);
  }

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-xl font-semibold">Select a Payment Method</h2>
      {paymentMethods?.map((method) => (
        <Radio
          key={method.id}
          name="PaymentMethod"
          className="border-secondary"
          iconProps={{
            className: "text-secondary rounded-full",
          }}
          checked={method.id === selectPaymentMethod}
          onChange={() => handleSetPaymentMethod(method.id)}
          label={
            <PaymentMethodCard
              method={method}
              defaultMethod={defaultMethod}
              selectPaymentMethod={selectPaymentMethod}
              customerId={customerId}
            />
          }
          labelProps={{
            className: "!flex !gap-2 !items-center mt-0",
          }}
          containerProps={{
            className: "!flex !gap-2 !items-center",
          }}
        />
      ))}
      <Radio
        key={"AddNewMethod"}
        name="PaymentMethod"
        checked={selectPaymentMethod === "AddNewMethod"}
        className="border-secondary"
        iconProps={{
          className: "text-secondary rounded-full",
        }}
        onChange={() => {
          setShowAddPaymentMethod(true);
          setSelectPaymentMethod("AddNewMethod");
        }}
        label={
          <p className="font-semibold text-debatePalette-title">
            {"Add Payment Method"}
          </p>
        }
        labelProps={{
          className: "!flex !gap-2 !items-center mt-0",
        }}
        containerProps={{
          className: "!flex !gap-2 !items-center",
        }}
      />
      {/* <Button
        className="bg-secondary w-full"
        onClick={() => setShowAddPaymentMethod(!showAddPaymentMethod)}
      >
        {showAddPaymentMethod ? "Cancel" : "Add Payment Method"}
      </Button> */}
      {showAddPaymentMethod && <PaymentMethodForm clientSecret={clientSecret} setSelectPaymentMethod={handleSetPaymentMethod} customerId={customerId} subscriptionId={subscriptionId} />}

      <Button
        size="sm"
        className="bg-primary text-secondary text-xs font-semibold flex justify-center items-center gap-3"
        onClick={handleSetDefaultPaymentMethod}
        loading={isUpdating}
        disabled={selectPaymentMethod === "AddNewMethod" || selectPaymentMethod === defaultMethod}
      >
        Set as default
      </Button>
    </div>
  );
};

export default PaymentMethods;
