import React, { Fragment } from "react";
import {
  PRIVATE_DEBATES,
  shadowBlue,
  shadowNormal,
} from "../../Utils/Constant";
import { Chip, Radio, Tooltip, Typography } from "@material-tailwind/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { handleUpgradePlan } from "../../Services/SweetAlert";

const RadioCardBlock = ({
  content,
  status,
  setStatus,
  isTooltip = false,
  navigate = () => {},
}) => {
  const renderRadioCard = (input, i) => {
    const notHasAccess = input?.notHasAccess || false;

    const handleClick = (status) => {
      if (notHasAccess) {
        handleUpgradePlan(PRIVATE_DEBATES, () => {
          navigate("/upgrade-plan?plan=base-plan");
        });
        return;
      }
      setStatus(status);
    };

    return (
      <Fragment key={i}>
        {" "}
        <Tooltip
          content={input?.tooltipContent}
          placement="right-start"
          className={`w-96  !rounded-lg !p-3 !shadow-shadowDebate !bg-debatePalette-background !text-secondary border border-x-debatePalette-buttonBorder ${
            isTooltip ? "hidden md:block" : "hidden"
          }`}
        >
          <label
            htmlFor={input.opt}
            className={`flex w-full cursor-pointer items-center px-3 py-2 gap-3 border  rounded-md ${
              input.value === status ? `${shadowBlue}` : `${shadowNormal}`
            }`}
          >
            <Radio
              color="blue"
              id={input.opt}
              ripple={false}
              className="hover:before:opacity-0"
              containerProps={{
                className: "p-0",
              }}
              checked={input.value === status}
              onClick={() =>
                handleClick(status === input.value ? "" : input.value)
              }
              onChange={() =>
                handleClick(status === input.value ? "" : input.value)
              }
            />
            <div className="flex flex-col justify-center w-full">
              <p
                className={` text-base flex gap-2 items-center justify-between  ${
                  input.value === status ? `font-semibold` : `font-normal`
                }`}
              >
                <span>{input?.opt} </span>
                {notHasAccess && (
                  <Chip
                    size="sm"
                    variant="outlined"
                    className="text-xs border-secondary text-secondary"
                    value="Upgrade to pro"
                  />
                )}
              </p>
              <p
                className={`${
                  input.value === status ? `font-semibold` : `font-normal`
                } text-debatePalette-title text-xs`}
              >
                {input?.subOpt}
              </p>
            </div>
          </label>
        </Tooltip>
        {input?.typeDetails && (
          <div
            className={`w-full cursor-pointer items-center px-3 py-2 gap-3 border  rounded-md ${
              input.value === status
                ? `${shadowBlue} block`
                : `${shadowNormal} hidden`
            }`}
          >
            <ul className="flex flex-col gap-4">
              {input?.typeDetails?.map((detail, i) => (
                <li className="flex items-center gap-4" key={i}>
                  <CheckIcon className="h-5 w-6 text-secondary font-bold !stroke-secondary " />
                  <Typography className="font-normal flex-1 text-sm">
                    {detail}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <div className="flex flex-col gap-3 w-full">
      {content?.extraDetail?.map((input, i) => renderRadioCard(input, i))}
    </div>
  );
};

export default RadioCardBlock;
