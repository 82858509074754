import { Button } from "@material-tailwind/react";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SelectedLine from "../../Assets/Images/SelectedLine.png";
import { useCancelSubscription } from "../../Web-Hooks/Subscription/use-subscription";

export function PricingCard({
  plan,
  isYearly,
  subscribedPlan,
  theme = "base",
}) {
  const {
    isRecommended,
    isContactUs,
    description,
    // isMonthlyPlan,
    // isYearlyPlan,
    monthlyAmonut,
    planKeywords,
    planName,
    yearlyAmonut,
    planId,
  } = plan;
  const navigate = useNavigate();
  const breakdownYearlyAmount = Number.isInteger(yearlyAmonut / 12)
    ? (yearlyAmonut / 12).toFixed(0)
    : (yearlyAmonut / 12).toFixed(2);
  const sendPrice = !isYearly ? monthlyAmonut : yearlyAmonut;
  const price = !isYearly ? monthlyAmonut : breakdownYearlyAmount;
  const type = isYearly
    ? "per user / month, billed yearly"
    : "per user / month";

  const queryClient = useQueryClient();

  const checkType = isYearly ? "year" : "month";

  const typeForCancellation = isYearly ? "per year" : "per month";
  const isCurrentPlan =
    planId === subscribedPlan?.planId &&
    subscribedPlan?.stripeJson?.items?.data[0]?.plan?.interval === checkType;
  const isCanceledAtPeriodEnd =
    subscribedPlan?.stripeJson?.cancel_at_period_end;

  const features = planKeywords.sort((a, b) =>
    a.included === b.included ? 0 : a.included ? -1 : 1
  );
  const { mutateAsync: cancelSubscription } = useCancelSubscription();

  const billingPeriodEndDate = moment(
    subscribedPlan?.planEndDate || new Date()
  ).format("MMMM D, YYYY");

  const isFreeTrialPeriodAvailable = subscribedPlan?.isTrialPlanAvailable;

  const notRecommendedText =
    theme === "base"
      ? "text-secondary font-semibold"
      : "text-secondary font-semibold";
  const recommendedBgColor =
    theme === "base"
      ? "bg-secondary text-primary"
      : "bg-gradient-to-tl from-AITheme-primary to-AITheme-secondary text-white";
  const recommendedButton =
    theme === "base"
      ? "bg-white text-secondary hover:scale-110 hover:shadow-lg"
      : "bg-white text-secondary hover:scale-110 hover:shadow-lg";
  const notRecommendedBgColor =
    theme === "base"
      ? "bg-white border border-secondary"
      : "bg-white border border-secondary";
  const notRecommendedButton =
    theme === "base"
      ? "border border-secondary text-secondary bg-transparent hover:bg-secondary hover:text-white hover:scale-110 hover:shadow-lg"
      : "border border-secondary text-secondary bg-transparent hover:bg-secondary hover:text-white hover:scale-110 hover:shadow-lg";

  const handleRedirection = () => {
    if (isContactUs) {
      navigate(
        `/contact-us?plan=${planName}&amount=${sendPrice}&planId=${planId}&type=${
          isYearly ? "year" : "month"
        }&contactUs=true`
      );
      return;
    }
    if (planName === "Free") {
      navigate(`/createworkspace`);
      return;
    }
    if (
      subscribedPlan?.planName !== "Free" &&
      subscribedPlan?.planName !== "Free (Trial Period)"
    ) {
      navigate(
        `update/${planName.toLowerCase()}?plan=${planName}&amount=${sendPrice}&planId=${planId}&type=${
          isYearly ? "year" : "month"
        }`,
        {
          state: {
            features: planKeywords,
            stripeSubscription: subscribedPlan?.stripeJson,
            currentPlanName: subscribedPlan?.planName,
          },
        }
      );
      return;
    }

    navigate(
      `${planName.toLowerCase()}?plan=${planName}&amount=${sendPrice}&planId=${planId}&type=${
        isYearly ? "year" : "month"
      }`,
      {
        state: {
          features: planKeywords,
        },
      }
    );
  };

  const handleCancelSubscription = () => {
    if (isCanceledAtPeriodEnd) {
      const newWindow = window.open(
        "https://billing.stripe.com/p/login/test_eVa14k41L2Vj7hS8ww",
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
      return;
    }
    Swal.fire({
      titleText: `${planName}`,
      html: `<div class="flex flex-col gap-4">        
        <p class="text-2xl font-bold">
          $${sendPrice.toFixed(2)} ${typeForCancellation}
        </p>
        <div class="flex flex-col gap-4">
                <p class="text-base text-justify">
          Your subscription will be canceled, but is still available until the
          end of your billing period on ${billingPeriodEndDate}.
        </p>
        <p class="text-base text-justify">
          If you change your mind, you can renew your subscription from the manage subscription page.
      <a href="https://billing.stripe.com/p/login/6oEdSzabUaQHdLacMM" target="_blank" class="text-secondary font-semibold hover:underline">(Customer Portal)</a>
        </p>
        </div>
      </div>`,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const result = await cancelSubscription(
            subscribedPlan?.subscriptionId
          ); // Call your async function
          await queryClient.invalidateQueries({
            queryKey: ["subscription"],
          });
          return result; // Pass data to the `then` block of Swal
        } catch (error) {
          Swal.showValidationMessage(`Error: ${error.message}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#153C58",
      confirmButtonText: "Yes, cancel it!",
    });
  };
  return (
    <div
      className={`relative rounded-3xl  py-6 px-8 w-80 ${
        isRecommended ? recommendedBgColor : notRecommendedBgColor
      }`}
    >
      <div className="space-y-6">
        <div className="text-center">
          <h3
            className={`text-2xl font-bold mb-1 ${
              isRecommended ? "text-primary" : notRecommendedText
            }`}
          >
            {planName}
          </h3>
          <p
            className={`text-sm h-10 ${
              isRecommended ? "text-primary" : notRecommendedText
            }`}
          >
            {description}
          </p>
        </div>

        {isContactUs ? (
          <div
            className={`flex items-baseline text-3xl font-bold justify-center h-[75px] ${
              isRecommended ? "text-primary" : notRecommendedText
            }`}
          >
            Custom Pricing
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-[75px]">
            <div className="flex items-baseline">
              <span
                className={`text-[2rem] font-bold ${
                  isRecommended ? "text-primary" : notRecommendedText
                }`}
              >
                $
              </span>
              <span
                className={`text-[2rem] font-bold ${
                  isRecommended ? "text-primary" : notRecommendedText
                }`}
              >
                {price}
              </span>
            </div>
            <span
              className={`ml-1 ${
                isRecommended ? "text-primary" : notRecommendedText
              }`}
            >
              {type}
            </span>
            {isRecommended && (
              <div>
                <img
                  src={SelectedLine}
                  alt="Selected Line"
                  className="w-full"
                />
              </div>
            )}
          </div>
        )}

        <ul className="space-y-2 h-64">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center gap-6">
              <div
                className={`w-3 h-3 rounded-full ${
                  feature.included
                    ? isRecommended
                      ? "bg-primary "
                      : theme === "base"
                      ? "bg-secondary"
                      : "bg-secondary"
                    : isRecommended
                    ? "bg-primary opacity-55"
                    : theme === "base"
                    ? "bg-secondary opacity-55"
                    : "bg-secondary opacity-55"
                }`}
              />
              <span
                className={`text-sm ${
                  feature.included
                    ? isRecommended
                      ? "text-primary font-semibold"
                      : notRecommendedText
                    : isRecommended
                    ? "text-primary font-semibold text-opacity-55"
                    : `${notRecommendedText} text-opacity-55`
                }`}
              >
                {feature.text}
              </span>
            </li>
          ))}
        </ul>
        <div className="h-20 flex flex-col gap-2">
          {planName === "Free" && isFreeTrialPeriodAvailable && (
            <Button
              disabled={isCurrentPlan}
              className={` py-2 px-8 w-full rounded-md transition-all duration-300 ${
                isRecommended ? recommendedButton : notRecommendedButton
              }`}
              onClick={handleRedirection}
            >
              Continue with free
            </Button>
          )}
          {planName !== "Free" && (
            <Button
              disabled={isCurrentPlan}
              className={` py-2 px-8 w-full rounded-md transition-all duration-300 ${
                isRecommended ? recommendedButton : notRecommendedButton
              }`}
              onClick={handleRedirection}
            >
              {isCurrentPlan && "Current Plan"}
              {!isCurrentPlan &&
                (isContactUs
                  ? "Contact Us"
                  : isFreeTrialPeriodAvailable
                  ? "Start your free trial"
                  : "Upgrade now")}
            </Button>
          )}
          {isCurrentPlan && planName !== "Free" && (
            <Button
              className={`${
                isCanceledAtPeriodEnd
                  ? isRecommended
                    ? "bg-debatePalette-background text-secondary"
                    : "bg-secondary"
                  : "bg-red-600 "
              } py-2 px-8 w-full rounded-md transition-all duration-300`}
              onClick={handleCancelSubscription}
            >
              {isCanceledAtPeriodEnd
                ? "Renew Subscription"
                : "Cancel Subscription"}
            </Button>
          )}
        </div>

        {/* <Link
          to={"https://buy.stripe.com/test_bIY9CxcfJ0p9h0Y4gh"}
          target="_self"
          rel="noopener noreferrer"
        >
          checkout
        </Link> */}
      </div>
    </div>
  );
}
