import React from "react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { defaultPlans } from "../../Context/PlanContext";

const featureCategories = {
  "Workspace & Forums & Users": [
    "maxCreateWorkspaceCount",
    "totalWorkspaceJoin",
    "maxCreateForumCount",
    "maxAddUserPerForum",
  ],
  Storage: ["libraryStorage", "storageOptions", "referenceStorage"],
  "Motion & Debates": [
    "suggestMotion",
    "findMotion",
    "maxCitationCount",
    "accessSaveDebate",
    "accessNews",
    "privateDebate",
  ],
  Messaging: ["messageOption", "createGroupMessage"],
  "AI & Admin Features": ["aiFeature", "roleManagement", "accessAdminPanel"],
};

const NewPlanTable = () => {

    const { TrialPeriod, ...newPlans } = defaultPlans

  const headers = Object.entries(newPlans).map(
    ([planId, { planName }]) => planName
  );

  const plans = Object.keys(newPlans);
  const data = Object.entries(featureCategories).map(
    ([category, features]) => ({
      category,
      features: features.map((feature) => ({
        name: feature,
        values: plans.map((plan) => newPlans[plan][feature]),
      })),
    })
  );

  return (
    <div className="overflow-x-auto p-8">
      <h2 className="text-2xl font-bold text-center mb-6">
        Compare Features Across Plans
      </h2>
      <table className="w-full border-collapse border border-gray-300 shadow-lg rounded-lg">
        <thead className="bg-gray-100 text-gray-900">
          <tr>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Feature
            </th>
            {headers.map((plan) => (
              <th
                key={plan}
                className="border border-gray-300 px-4 py-2 text-center"
              >
                {plan}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white text-gray-900">
          {data.map(({ category, features }) => (
            <React.Fragment key={category}>
              <tr className="bg-gray-200">
                <td colSpan={plans.length + 1} className="font-bold px-4 py-2">
                  {category}
                </td>
              </tr>
              {features.map(({ name, values }, index) => (
                <tr key={index} className="border border-gray-300">
                  <td className="border border-gray-300 px-4 py-2 capitalize">
                    {name.replace(/([A-Z])/g, " $1").trim()}
                  </td>
                  {values.map((value, i) => (
                    <td
                      key={i}
                      className="border border-gray-300 px-4 py-2 text-center"
                    >
                      {typeof value === "boolean" &&
                        (value ? (
                          <CheckIcon className="text-green-500 w-full h-6 text-center" />
                        ) : (
                          <XMarkIcon className="text-red-500 w-full h-6" />
                        ))}
                      {(typeof value === "string" || typeof value === "number") && (
                        <span className="text-center">{value ? value : "-"}</span>
                      )}
                      {typeof value === "object" && (
                        <span className="text-center capitalize">
                          {value?.map((item) => item).join(", ")}
                        </span>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NewPlanTable;
