import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import {
  acceptJoinRequest,
  addWorkspace,
  createWorkspace,
  deleteUserFromWorkspace,
  getAllPendingRequests,
  getWorkspaceByUserId,
  getWorkspaceUserByWorkspaceId,
  updateWorkspace,
} from "../../api/workspace/workspace";
import { useAuth } from "../../Context/AuthContext";
import { getSubWorkspaceByWorkId } from "../../api/subworkspace/subworkspace";

// Hook to get workspaces by user ID

export const useGetAllPendingRequests = (userEmail) => {
  return useQuery({
    queryKey: ["pending-Requests", userEmail],
    queryFn: () => getAllPendingRequests(userEmail),
    select: (data) => data.data,
    enabled: !!userEmail,
  });
};

export const useAcceptJoinRequest = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body) => {
      return await acceptJoinRequest(body);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["workspaces"],
        }),
      ]);
    },
  });
};

export const useWorkspacesByUserId = (userId) => {
  return useQuery({
    queryKey: ["workspaces", userId],
    queryFn: () => getWorkspaceByUserId(userId),
    select: (data) => {
      return data.listWorkspaces;
    },
    enabled: !!userId,
  });
};

export const useAddWorkspace = () => {
  const queryClient = useQueryClient();
  const { selectWorkspace } = useWorkspace();
  const { selectSubWorkspace } = useSubWorkspace();
  return useMutation({
    mutationFn: async (workspace) => {
      return await addWorkspace(workspace);
    },
    onSuccess: async (res) => {
      const user = JSON.parse(localStorage.getItem("user"));
      const userId = user?.userId;
      const data = await queryClient.fetchQuery({
        queryKey: ["workspaces", userId],
        queryFn: () => getWorkspaceByUserId(userId),
      });
      const findOne = data?.listWorkspaces.find(
        (workspace) => workspace.workSpaceId === res.workspaceId
      );
      if (findOne) {
        selectWorkspace(findOne);
        const subworkspaceList = await getSubWorkspaceByWorkId(
          res.workspaceId,
          userId
        );
        const subworkspace = subworkspaceList?.listOfSubworkspaces[0];
        if (subworkspace) {
          selectSubWorkspace(subworkspace);
        }
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useCreateWorkspace = () => {
  const queryClient = useQueryClient();
  const { selectWorkspace } = useWorkspace();
  const { selectSubWorkspace } = useSubWorkspace();
  return useMutation({
    mutationFn: async (workspace) => {
      return await createWorkspace(workspace);
    },
    onSuccess: async (res) => {
      if (res?.error) {
        return res;
      }
      const user = JSON.parse(localStorage.getItem("user"));
      const userId = user?.userId;
      const data = await queryClient.fetchQuery({
        queryKey: ["workspaces", userId],
        queryFn: () => getWorkspaceByUserId(userId),
      });
      const findOne = data?.listWorkspaces.find(
        (workspace) => workspace.workSpaceId === res?.data?.workspaceId
      );
      if (findOne) {
        selectWorkspace(findOne);
        const subworkspaceList = await getSubWorkspaceByWorkId(
          res?.data?.workspaceId,
          userId
        );
        const subworkspace = subworkspaceList?.listOfSubworkspaces[0];
        if (subworkspace) {
          selectSubWorkspace(subworkspace);
        }
      }
      return res;
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
// Hook to update an existing workspace
export const useUpdateWorkspace = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (workspace) => {
      return await updateWorkspace(workspace);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["workspaces"] });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useGetWorkspaceUserByWorkId = (workId, search = "") => {
  const { user: loggedInUser } = useAuth();

  return useQuery({
    queryKey: ["workspace-users", workId, loggedInUser?.userId, search],
    queryFn: async () =>
      await getWorkspaceUserByWorkspaceId(workId, loggedInUser?.userId, search),
    select: (data) => {
      const users = data.listWorkSpaceUsers.filter(
        (user) => user?.userId !== loggedInUser?.userId
      );
      return users;
    },
    enabled: (!!workId && !!loggedInUser?.userId) || !!search,
  });
};

export const useDeleteUserFromWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (deleteUser) => {
      return await deleteUserFromWorkspace(deleteUser);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["workspace-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["workspaces"],
        }),
      ]);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
