import { PencilSquareIcon } from "@heroicons/react/24/outline";
import {
  Chip,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Tooltip,
} from "@material-tailwind/react";
import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import DirectMessageNav from "./DirectMessageNav";
import GroupMessageNav from "./GroupMessageNav";
import { usePersistedState } from "../../Services/usePersistedState";

const MessageToggleNav = ({ currentPlan }) => {
  const [activeTab, setActiveTab] = usePersistedState("messageTab", "message");
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();
  const [unreadCount, setUnreadCount] = useState({
    message: 0,
    group: 0,
  });
  const navigate = useNavigate();

  const data = [
    {
      label: "Message",
      value: "message",
      unReadCount: unreadCount?.message,
      Component: (
        <DirectMessageNav
          selectedSubWorkspace={selectedSubWorkspace}
          selectedWorkspace={selectedWorkspace}
          setUnreadCount={setUnreadCount}
        />
      ),
    },
    {
      label: "Groups",
      value: "group",
      unReadCount: unreadCount?.group,
      Component: (
        <GroupMessageNav
          selectedSubWorkspace={selectedSubWorkspace}
          selectedWorkspace={selectedWorkspace}
          setGroupUnreadCount={setUnreadCount}
        />
      ),
    },
  ];

  const handleUpgrade = () => {
    if (currentPlan.message) return;

    navigate("/upgrade-plan?plan=base-plan");
  };
  return (
    <Fragment>
      <div
        className={`px-4 flex justify-between items-center ${
          !currentPlan?.message && "cursor-pointer"
        }`}
        onClick={handleUpgrade}
      >
        <div
          key={"DirectMessage"}
          className={` py-2 rounded-none text-lg truncate text-ellipsis scrollbar-hide font-semibold text-debatePalette-background `}
        >
          {"Direct Message"}
        </div>
        {!currentPlan?.message && (
          <Tooltip content={"Upgrade to Pro"}>
            <Chip
              size="sm"
              variant="outlined"
              value={"Pro"}
              className="cursor-pointer text-primary border-primary bg-transparent hover:bg-primary hover:text-white"
            />
          </Tooltip>
        )}

        {currentPlan?.message && (
          <Link to="/message">
            <PencilSquareIcon className="h-6 w-6 text-white cursor-pointer hover:text-debatePalette-buttonBorder" />
          </Link>
        )}
      </div>
      {currentPlan?.message && (
        <Tabs value={activeTab}>
          <TabsHeader
            className="rounded-none border-b px-2 py-0 w-full border-transparent bg-transparent"
            indicatorProps={{
              className:
                "bg-transparent border-b-2 border-primary shadow-none rounded-none",
            }}
          >
            {data.map(({ label, value, unReadCount }) => (
              <Tab
                key={value}
                value={value}
                onClick={() => setActiveTab(value)}
                className={
                  activeTab === value
                    ? "text-primary font-semibold"
                    : "text-debatePalette-background"
                }
              >
                <div className="flex gap-2 text-sm font-semibold ">
                  <span>{label}</span>
                  {!!unReadCount && <span>{unReadCount}</span>}
                </div>
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody>
            {data.map(({ value, Component }) => (
              <TabPanel className="p-0" key={value} value={value}>
                {Component}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      )}
    </Fragment>
  );
};

export default MessageToggleNav;
