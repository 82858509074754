import React, { Fragment, useState } from "react";
import { PricingToggle } from "../Payment/PricingToggle";
import PlansTab from "./PlansTab";
import PlanTable from "./PlanTable";

const SubscriptionPlans = () => {
  const [isYearly, setIsYearly] = useState(false);


  return (
    <Fragment>
      <div className="w-full flex flex-col gap-2">
        <div className="w-full">
          <h1 className="text-5xl font-bold text-center">Select Your Plan</h1>
          <h6 className="text-md font-bold text-center">
            Pay securely online and manage the booking via desktop or via the
            mobile app.{" "}
          </h6>
        </div>
        <div className="flex justify-center w-full ">
          <PricingToggle isYearly={isYearly} onChange={setIsYearly} />
        </div>
        <PlansTab isYearly={isYearly} />

        <PlanTable/>
      </div>
    </Fragment>
  );
};

export default SubscriptionPlans;
