import React, { Fragment, useEffect, useState } from "react";
import CSVUploader from "../../Components/Functionality/CSVUploader";
import DownloadSampleCSV from "../../Utils/DownloadSampleCSV";
import ConatctList from "../../Components/Functionality/ConatctList";
import { Dialog, DialogBody, DialogHeader } from "@material-tailwind/react";
import useDebounce from "../../Services/useDebounce";
import { usePlan } from "../../Context/PlanContext";

const CSVContacts = ({ selectedMembers, setSelectedMembers }) => {
  const [contacts, setContacts] = useState([]);
  const [displayContacts, setDisplayContacts] = useState([]);
  const [openMemberSelect, setOpenMemberSelect] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const deferredSearch = useDebounce(searchValue, 300);
  const {currentPlan} = usePlan();

  useEffect(() => {
    if (deferredSearch) {
      const filteredList = contacts.filter((item) =>
        item.label.toLowerCase().includes(deferredSearch.toLowerCase())
      );
      setDisplayContacts(filteredList);
    } else {
      setDisplayContacts(contacts);
    }
  }, [deferredSearch, contacts]);

  return (
    <Fragment>
      <Dialog open={openMemberSelect} handler={setOpenMemberSelect}>
        <DialogHeader>CSV contacts</DialogHeader>
        <DialogBody>
          <ConatctList
            contacts={contacts}
            displayContacts={displayContacts}
            setOpenMemberSelect={setOpenMemberSelect}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            selectedContactsFromProp={selectedMembers}
            setSelectedContactsFromProp={setSelectedMembers}
            maxLimit={currentPlan?.maxAddUserPerForum}
          />
        </DialogBody>
      </Dialog>
      <CSVUploader
        selectedContacts={selectedMembers}
        setSearchValue={setSearchValue}
        setDisplayContacts={setDisplayContacts}
        setContacts={setContacts}
        setOpenMemberSelect={setOpenMemberSelect}
      />
      <DownloadSampleCSV />
    </Fragment>
  );
};

export default CSVContacts;
