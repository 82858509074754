import { Checkbox } from "@material-tailwind/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import InfiniteScroll from "../../Components/Functionality/InfiniteScrollList";
import NoData from "../../Components/Functionality/NoData";
import EditableBodyPopup from "../../Components/Popup/EditableBodyPopup";
import InfoPopup from "../../Components/Popup/InfoPopup";
import SelectFutureDebateOptionPopup from "../../Components/Popup/SelectFutureDebateOptionPopup";
import NotificationCard from "../../Components/UI/Cards/NotificationCard";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { getUTCDate } from "../../Utils/Helper";
import {
  useConvertToOpenDebate,
  useGetDebateDetails,
  useNominateNewUserToDebate,
} from "../../Web-Hooks/Debate/use-debate";
import {
  useGetNotificationByUserId,
  useUpdateNotificationStatus,
} from "../../Web-Hooks/Notification/use-notification";
import { useGetSubWorkspaceUserBySubId } from "../../Web-Hooks/Workspace/use-subworkspace";
import { useGetWorkspaceUserByWorkId } from "../../Web-Hooks/Workspace/use-workspace";

const Notification = ({ user }) => {
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetNotificationByUserId(user?.userId);
  const queryClient = useQueryClient();
  const [notification, setNotification] = useState(null);
  const [nominatePopup, setNominatePopup] = useState(false);
  const [rejectPopup, setRejectPopup] = useState(false);
  const [opponentId, setOpponentId] = useState("");
  const [futureAction, setFutureAction] = useState(null);
  const [futureActionPopup, setFutureActionPopup] = useState(false);
  const { mutateAsync: handleConvertToOpen, isPending: isConvertPending } =
    useConvertToOpenDebate();
  const { mutateAsync: handleNominate, isPending: isNominatePending } =
    useNominateNewUserToDebate();
  const { mutateAsync: updateNotificationStatus, isPending: isUpdatePending } =
    useUpdateNotificationStatus();

  const handleNominatePopup = (id) => {
    if (id === opponentId) {
      setOpponentId("");
      return;
    }
    setOpponentId(id);
  };
  const handleSubmission = (type) => {
    const statusObj = {
      NotificationId: notification?.notificationId,
      IsActionTaken: true,
    };
    if (type === "open") {
      const debate = {
        debateId: notification?.debateId,
        IsOpenDebate: true,
      };
      handleConvertToOpen(debate)
        .then((res) => {
          if (res) {
            updateNotificationStatus(statusObj).then((res2) => {
              toast.success(res.message);
            });
          }
        })
        .catch((error) => toast.error(error.message))
        .finally(() => setRejectPopup(false));
    } else if (type === "nominate") {
      const debate = {
        NominateUserId: opponentId,
        DebateId: notification?.debateId,
        CreatedUserId: user?.userId,
        SubWorkSpaceId: notification?.subWorkSpaceId,
        CreatedAt: getUTCDate(),
      };
      handleNominate(debate)
        .then((res) => {
          console.log(res);

          if (res) {
            updateNotificationStatus(statusObj).then((res) => {
              setOpponentId("");
              setNominatePopup(false);
              setFutureActionPopup(false);
            });
            toast.success(res.message);
          }
        })
        .catch((error) => toast.error(error.message));
    }
  };

  const notifications = data?.pages?.flatMap((page) => page?.notificationLists);

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: ["notification"],
      });
    };
  }, [queryClient, user]);

  const handleClick = (notification) => {
    if (notification?.notificationType === "Rejected") {
      return;
    }
  };

  if (isLoading) {
    return <LoadingSpinner height={80} width={80} color={"#153C58"} />;
  }

  return (
    <div className="w-full h-full">
      {rejectPopup ? (
        <InfoPopup
          title={"Nomination Rejected"}
          description={`The nominated user has rejected your debate. Would you like to open it up?`}
          open={rejectPopup}
          handleClose={() => setRejectPopup(false)}
          btn1={"Yes"}
          btn2={"No"}
          loading1={isConvertPending || isUpdatePending}
          onClickBtn1={() => handleSubmission("open")}
          onClickBtn2={() => {
            setRejectPopup(false);
          }}
        />
      ) : null}
      {nominatePopup ? (
        <EditableBodyPopup
          title={"Nominate new opponent"}
          open={nominatePopup}
          handleClose={() => {
            setOpponentId("");
            setNominatePopup(false);
          }}
          btn1={"cancel"}
          btn2={"Nominate"}
          disableBtn={!opponentId}
          onClickBtn1={() => {
            setOpponentId("");
            setNominatePopup(false);
          }}
          onClickBtn2={() => setFutureActionPopup(true)}
        >
          {
            <UserList
              handleNominatePopup={handleNominatePopup}
              notification={notification}
              opponentId={opponentId}
            />
          }
        </EditableBodyPopup>
      ) : null}
      {futureActionPopup ? (
        <SelectFutureDebateOptionPopup
          setFutureAction={setFutureAction}
          futureAction={futureAction}
          futureActionPopup={futureActionPopup}
          setFutureActionPopup={setFutureActionPopup}
          handleClose={() => {
            setFutureActionPopup(false);
            setFutureAction(null);
          }}
          loading={isNominatePending || isUpdatePending}
          handleSubmission={() => handleSubmission("nominate")}
        />
      ) : null}

      <InfiniteScroll
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
      >
        {notifications?.length > 0 ? (
          notifications?.map((notification) => (
            <div
              onClick={() => handleClick(notification)}
              key={notification?.notificationId}
            >
              <NotificationCard
                notification={notification}
                key={notification.notificationId}
                setNotification={setNotification}
                setRejectPopup={setRejectPopup}
                setNominatePopup={setNominatePopup}
              />
            </div>
          ))
        ) : (
          <NoData message={"No Notification"} size={"w-full h-[80%]"}></NoData>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default Notification;

const UserList = ({ notification, handleNominatePopup, opponentId }) => {
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();

  const { data: SubWorkspaceUser, isLoading } = useGetSubWorkspaceUserBySubId(
    selectedSubWorkspace?.subWorkSpaceId
  );

  const { data: workspaceUser, isLoading: isWorkspaceLoading } =
    useGetWorkspaceUserByWorkId(selectedWorkspace?.workSpaceId);
  const { data: debateDetails } = useGetDebateDetails(
    notification?.debateId,
    notification?.notificationType === "Reject" ||
      notification?.notificationType === "cronjobnominate"
  );

  const Users = debateDetails?.isDebatePublic
    ? workspaceUser
    : SubWorkspaceUser;

  return (
    <form className="flex flex-col overflow-y-auto member-list w-full items-start gap-2">
      {(isLoading || isWorkspaceLoading) && (
        <LoadingSpinner height={80} width={80} color={"#153C58"} />
      )}
      {!isLoading &&
        !isWorkspaceLoading &&
        Users?.map(
          (member) =>
            member.isAccept && (
              <Checkbox
                key={member?.email}
                containerProps={{
                  className: "!flex items-center gap-4",
                }}
                checked={opponentId === member?.userId}
                onChange={() => handleNominatePopup(member?.userId)}
                id={member?.email}
                color="blue"
                ripple={false}
                className="w-4 h-4 rounded-none before:content-none"
                label={
                  <div className="flex items-center gap-2 flex-1 w-full truncate">
                    {
                      <ImageOrInitials
                        imageSrc={member?.userImage}
                        initials={member?.userName || member?.email}
                        size={"h-10 w-10"}
                        classNameInitials={"shadow-none"}
                      />
                    }
                    <p className=" text-md first-letter:capitalize lowercase">
                      {member?.userName || member?.email?.replace(/\s+/g, "")}
                    </p>
                  </div>
                }
                labelProps={{
                  className:
                    "text-debatePalette-title w-full text-md font-semibold capitalize",
                }}
              />
            )
        )}
    </form>
  );
};
